import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { addAds, editAds, getAdsList, resetAddEditDeleteAds } from "../../../../../redux/slices/ads_slice";

export default function AddEditAdDialog({ onHide, editCode, permissions }) {
    const adsReducer = useSelector((state) => state.adsList);
    const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = adsReducer;

    const dispatch = useDispatch();

    const [adImage, setAdImage] = useState(null);

    const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("This field is required."),
        redirectUrl: Yup.string().matches(re, "Please add valid url").required("This field is required."),
        buttonText: Yup.string().required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: "",
            redirectUrl: "",
            buttonText: "",
        },

        onSubmit: async (data) => {
            if (editCode == null) {
                if (adImage === null) {
                    return toast.warn("Please choose an add image");
                }
                let imageBase64 = await readFileAsDataURL(adImage);
                data["image"] = imageBase64;
                dispatch(addAds(data));
            } else {
                data["id"] = editCode._id;
                if (adImage != null) {
                    let imageBase64 = await readFileAsDataURL(adImage);
                    data["image"] = imageBase64;
                }
                dispatch(editAds(data));
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Advertisement successfully added");

                onHide();
                dispatch(getAdsList());
            } else {
                toast.error(addError);
            }
        }

        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Add successfully updated");

                onHide();
                dispatch(getAdsList());
            } else {
                toast.error(editError);
            }
        }

        return () => {
            dispatch(resetAddEditDeleteAds());
        };
    }, [addSuccess, addError, editSuccess, editError]);

    useEffect(() => {
        if (editCode != null) {
            loadInitialValues();
        }
    }, []);

    const loadInitialValues = () => {
        formik.setFieldValue("title", editCode.title);
        formik.setFieldValue("redirectUrl", editCode.redirectUrl);
        formik.setFieldValue("buttonText", editCode.buttonText);
    };

    async function readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return result_base64;
    }
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField
                        iden="imageUrl"
                        label="Image"
                        formik={formik}
                        onChange={(e) => {
                            setAdImage(e.target.files[0]);
                        }}
                        type="file"
                    />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="title" label="Title" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="redirectUrl" label="Redirect URL" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="buttonText" label="Button Text" formik={formik} type="text" />
                </div>
                <div className="col-12 p-fluid">
                    <div className="button_class-modal">{permissions?.edit && editCode !== null && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                    <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                </div>
            </div>
        </form>
    );
}
