export const GET_USER_RIDES_REQUEST = 'GET_USER_RIDES_REQUEST';
export const GET_USER_RIDES_SUCCESS = 'GET_USER_RIDES_SUCCESS';
export const GET_USER_RIDES_FAILURE = 'GET_USER_RIDES_FAILURE';

export const GET_RIDE_DETAILS_REQUEST = 'GET_RIDE_DETAILS_REQUEST';
export const GET_RIDE_DETAILS_SUCCESS = 'GET_RIDE_DETAILS_SUCCESS';
export const GET_RIDE_DETAILS_FAILURE = 'GET_RIDE_DETAILS_FAILURE';


export const GET_ALL_RIDES_REQUEST = 'GET_ALL_RIDES_REQUEST';
export const GET_ALL_RIDES_SUCCESS = 'GET_ALL_RIDES_SUCCESS';
export const GET_ALL_RIDES_FAILURE = 'GET_ALL_RIDES_FAILURE';