import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const getReceiptDetailsRedcuer = createSlice({
    name: 'documentVerification',
    initialState: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReceiptDetails.pending, (state, action) => {
                return { loading: true }
            })
            .addCase(getReceiptDetails.fulfilled, (state, action) => {

                return { loading: false, data: action.payload }
            })
            .addCase(getReceiptDetails.rejected, (state, action) => {

                return {
                    loading: false,
                    error: action.payload
                }
            });
    },
});

export default getReceiptDetailsRedcuer.reducer;

// Thunks
export const getReceiptDetails = createAsyncThunk('getReceiptDetails/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getReceiptDetails + `?scheduleId=${id}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }


});
