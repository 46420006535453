import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const adsSlice = createSlice({
    name: "adsSlice",
    initialState: {},
    reducers: {
        resetAddEditDeleteAds(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdsList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getAdsList.fulfilled, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    data: action.payload.data,
                    count: action.payload.count,
                };
            })
            .addCase(getAdsList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });

        builder
            .addCase(addAds.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(addAds.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addAds.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(editAds.pending, (state, action) => {
                return { ...state, editLoading: true };
            })
            .addCase(editAds.fulfilled, (state, action) => {
                return { ...state, editLoading: false, editData: action.payload, editSuccess: true };
            })
            .addCase(editAds.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    error: action.payload,
                    editSuccess: false,
                };
            });
        builder
            .addCase(deleteAds.pending, (state, action) => {
                return { ...state, deleteLoading: true };
            })
            .addCase(deleteAds.fulfilled, (state, action) => {
                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true };
            })
            .addCase(deleteAds.rejected, (state, action) => {
                return {
                    ...state,
                    deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false,
                };
            });
    },
});

export default adsSlice.reducer;
export const { resetAddEditDeleteAds } = adsSlice.actions;

// Thunks
export const getAdsList = createAsyncThunk("ads/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAdsList + `/?pageNumber=${body.pageNumber}&text=${body.text}`);

        return fulfillWithValue({ data: data.data, count: data.count });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const addAds = createAsyncThunk("ads/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addAds, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const editAds = createAsyncThunk("ads/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editAds, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const deleteAds = createAsyncThunk("ads/delete", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deleteAds + `?id=${body.adId}`);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
