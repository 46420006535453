import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import AllRidesComponents from "../components/all_rides_component";

export default function AllRidesScreen({ flag, permissions }) {
    return (
        <>
            <div className="card">
                <TabView>
                    <TabPanel header="Owners">
                        <AllRidesComponents permissions={permissions} flag={flag} userType="1" />
                    </TabPanel>
                    <TabPanel header="Passengers">
                        <AllRidesComponents permissions={permissions} flag={flag} userType="2" />
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
}
