import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useParams } from "react-router-dom";
import PassengerActiveTab from "../components/passenger_active_tab";
import PassengerHistoryTab from "../components/passenger_history_tab";
import PassengerOtherTab from "../components/passenger_other_tab";

export default function UserRideScreen({ permissions }) {
    const param = useParams();
    const { userId } = param;

    return (
        <div className="card">
            <h3>Schedules and Rides</h3>

            {
                <TabView>
                    <TabPanel header="Schedules">
                        <TabView>
                            <TabPanel header="Active">{<PassengerActiveTab permissions={permissions} userId={userId} userType={"2"} />}</TabPanel>
                            <TabPanel header="Other">{<PassengerOtherTab permissions={permissions} userId={userId} userType={"2"} />}</TabPanel>
                        </TabView>
                    </TabPanel>
                    <TabPanel header="Rides">
                        <TabView>
                            <TabPanel header="History">{<PassengerHistoryTab permissions={permissions} userId={userId} userType={"2"} />}</TabPanel>
                        </TabView>
                    </TabPanel>
                </TabView>
            }
        </div>
    );
}
