import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const versionControlSlice = createSlice({
    name: "versionControl",
    initialState: {},
    reducers: {
        resetUpdateVersion(state, action) {
            return { ...state, updateSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVersionControl.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getVersionControl.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getVersionControl.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });

        builder
            .addCase(updateVersionControl.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateVersionControl.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateVersionControl.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false,
                };
            });
    },
});

export default versionControlSlice.reducer;
export const { resetUpdateVersion } = versionControlSlice.actions;

// Thunks
export const getVersionControl = createAsyncThunk("versionControl/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getVersionControl);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const updateVersionControl = createAsyncThunk("versionControl/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.updateVersionControl, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
