import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomInputField from "../../../components/custom_input_filed";
import { resetAdminUser, resetPasswordAdminUser } from "../../../../../redux/slices/admin_user_slice";
import { toast } from "react-toastify";
const ResetPasswordScreen = () => {
    //redux
    const loginUserReducer = useSelector((state) => state.loginUser);
    const { resetPassword, loading, user } = loginUserReducer;
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("email is required."),
    });

    const formik = useFormik({
        initialValues: {
            email: user ? user.email : "",
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            dispatch(resetPasswordAdminUser(data));
        },
    });

    useEffect(() => {
        if (resetPassword !== undefined) {
            if (resetPassword === true) {
                toast.success("Password updated");
                localStorage.clear();
                setTimeout(() => {
                    window.location.href = "/";
                }, 2000);
            } else {
                toast.error("Invalid credentials");
            }
        }
        return () => {
            dispatch(resetAdminUser());
        };
    }, [resetPassword]);

    return (
        <div className="login_body pt-5">
            <div className="card w__60">
                <div align="center" style={{ marginTop: "", marginBottom: "1%" }}>
                    <img src="app/logo2.png" alt="" width={"40%"} />
                </div>
                <div className="">
                    <div className="wdth__70">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form__mrg__top p-fluid">
                                <div className="mt-2 mb-5 text-center"></div>
                                <div className="field custom__style">
                                    <CustomInputField iden={"email"} disabled={user} formik={formik} type="text" />
                                </div>
                            </div>
                            <div className="button_class">
                                <Button loading={loading} type="submit" label="Update Password" className="p-button-success btn__default" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordScreen;
