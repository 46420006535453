import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomInputField from "../../../components/custom_input_filed";
import { loginAdminUser, resetAdminUser } from "../../../../../redux/slices/admin_user_slice";
import { toast } from "react-toastify";

const LoginScreen = () => {
    //redux
    const loginUserReducer = useSelector((state) => state.loginUser);
    const { loading, loginSuccess, loginError } = loginUserReducer;

    const dispatch = useDispatch();
    const history = useHistory();
    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required.").min(8, "Minimum length should be 8"),
        userName: Yup.string().required("Username is required."),
    });

    const formik = useFormik({
        initialValues: {
            userName: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            dispatch(loginAdminUser(data));
        },
    });

    useEffect(() => {
        if (loginSuccess !== undefined) {
            if (loginSuccess === true) {
                toast.success("Logged In successfully");
            } else {
                toast.error("Invalid credentials");
            }
        }
        return () => {
            dispatch(resetAdminUser());
        };
    }, [loginSuccess, loginError]);

    const resetPassword = (e) => {
        history.push("/resetpassword");
    };

    return (
        <div className="login_body pt-5">
            <div className="card w__60">
                <div align="center" style={{ marginTop: "", marginBottom: "1%" }}>
                    <img src="app/logo2.png" alt="" width={"40%"} />
                </div>
                <div className="">
                    <div className="wdth__70">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form__mrg__top p-fluid">
                                <div className="mt-2 mb-5 text-center"></div>
                                <div className="field custom__style">
                                    <CustomInputField iden={"userName"} formik={formik} type="text" />
                                </div>
                                <div className="field custom__style">
                                    <CustomInputField iden={"password"} formik={formik} type="password" placeholder="Please enter a password" feedback={false} />
                                </div>
                                <div className="field custom__style reset_password">
                                    <span className="" onClick={resetPassword}>
                                        Reset Password
                                    </span>
                                </div>
                            </div>
                            <div className="button_class">
                                <Button loading={loading} type="submit" label="Login" className="p-button-success btn__default" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
