import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
// import { loginAction } from "../../redux/actions/authAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
// import { Toast } from 'primereact/toast';
// import CustomInputField from "../../../components/custom_input_filed";
// import { loginAdminUser, resetAdminUser } from "../../../../../redux/slices/admin_user_slice";
import { toast } from "react-toastify";
import CustomInputField from "./custom_input_filed";
import { loginAdminUser, resetAdminUser } from "../../../redux/slices/admin_user_slice";
import routes from "../constants/routes";

const NotFound = () => {
    let isValidPath = false;
    const history = useHistory();

    console.log(window.location.hash);
    const searchRoute = window.location.hash.replace("#", "");
    const routeStartsWithSearchRoute = routes.find((route) => route.path.startsWith(searchRoute));
    isValidPath = typeof routeStartsWithSearchRoute != "object";

    const goToHome = (e) => {
        history.push("/");
    };
    console.log({ isValidPath });

    return (
        <div className="">
            <div className="card w__60" style={{ padding: "4rem" }}>
                <div className="flex flex-column justify-content-center align-items-center">
                    {isValidPath ? (
                        <>
                            <h1 className="h1_not_found">404</h1>
                            <h3 className="h3_not_found">Page not found</h3>
                        </>
                    ) : (
                        <p>You don't have permission to access this page</p>
                    )}
                    <div className="col-12 md:col-12">
                        <div className="button_class-modal">
                            <Button onClick={goToHome} icon="pi pi-arrow-left" type="submit" label="Go to home" className="p-button-success btn__default_modal p-button-rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
