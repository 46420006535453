import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    isSocketConnected: false,
};

export const toggleConnection = createAsyncThunk("connection/toggle", async (value, thunkAPI) => {
    //console.log({ value });
    return value;
});

const connectionSlice = createSlice({
    name: "connection",
    initialState,
    reducers: {
        toggleConnected: (state, action) => {
            state.isSocketConnected = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(toggleConnection.fulfilled, (state, action) => {
            state.isSocketConnected = action.payload;
        });
    },
});

export const { toggleConnected } = connectionSlice.actions;
export const selectConnected = (state) => state.connection.isSocketConnected;
export default connectionSlice.reducer;
