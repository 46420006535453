import React, { createContext, useState, useRef, useEffect } from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import appUrl from "./app/features/constants/appUrl";

const SocketContext = createContext();

const socket = io(appUrl.socketBaseUrl);
console.log(socket);

const ContextProvider = ({ children }) => {
    // const [callAccepted, setCallAccepted] = useState(false);
    // const [callEnded, setCallEnded] = useState(false);
    // const [stream, setStream] = useState();
    // const [name, setName] = useState("");
    // const [call, setCall] = useState({});
    // const [me, setMe] = useState("");

    // const myVideo = useRef();
    // const userVideo = useRef();
    // const connectionRef = useRef();

    // const resetVideoRefs = () => {
    //     // myVideo.current.srcObject = null;
    //     userVideo.current.srcObject = null;
    // };

    // useEffect(() => {
    //     navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((currentStream) => {
    //         setStream(currentStream);
    //         // myVideo.current.srcObject = currentStream;
    //     });

    //     socket.on("me", (id) => setMe(id));
    //     socket.emit("joinRoom", "admin");

    //     socket.on("callUser", ({ from, name: callerName, signal }) => {
    //         console.log({ from, name: callerName, signal });
    //         setCall({ isReceivingCall: true, from, name: callerName, signal });
    //     });
    //     socket.on("dropCall", () => {
    //         console.log("here");
    //         setCall({});
    //         setCallEnded(true);
    //         connectionRef.current.destroy();
    //         // window.location.reload();
    //     });
    // }, []);

    // const answerCall = () => {
    //     setCallAccepted(true);

    //     const peer = new Peer({ initiator: false, trickle: false, stream });

    //     peer.on("signal", (data) => {
    //         socket.emit("answerCall", { signal: data, to: call.from });
    //     });

    //     peer.on("stream", (currentStream) => {
    //         if (userVideo.current) userVideo.current.srcObject = currentStream;
    //     });

    //     peer.signal(call.signal);

    //     connectionRef.current = peer;
    // };

    // const callUser = (id) => {
    //     const peer = new Peer({ initiator: true, trickle: false, stream });

    //     peer.on("signal", (data) => {
    //         socket.emit("callUser", {
    //             userToCall: id,
    //             signalData: data,
    //             from: me,
    //             name,
    //         });
    //     });

    //     peer.on("stream", (currentStream) => {
    //         userVideo.current.srcObject = currentStream;
    //     });

    //     socket.on("callAccepted", (signal) => {
    //         setCallAccepted(true);
    //         peer.signal(signal);
    //     });

    //     connectionRef.current = peer;
    // };

    // const leaveCall = (id) => {
    //     setCall({});
    //     setCallEnded(true);
    //     resetVideoRefs();
    //     connectionRef.current.destroy();
    //     socket.emit("dropCall", { ...call, userToCall: id });
    //     // window.location.reload();
    // };
    return (
        <SocketContext.Provider
            value={{
                // call,
                // callAccepted,
                // myVideo,
                // userVideo,
                // stream,
                // name,
                // setName,
                // callEnded,
                // me,
                // callUser,
                // leaveCall,
                // answerCall,
                socket,
            }}
        >
            {children}
        </SocketContext.Provider>
    );
};

export { ContextProvider, SocketContext };
