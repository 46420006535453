import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';



const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const tutorialSlice = createSlice({
    name: 'tutorialSlice',
    initialState: {},
    reducers: {
        resetAddEditTutorials(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTutorialsList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getTutorialsList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };

            })
            .addCase(getTutorialsList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
        builder
            .addCase(addTutorial.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addTutorial.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addTutorial.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(editTutorial.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editTutorial.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editTutorial.rejected, (state, action) => {

                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(deleteTutorial.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteTutorial.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(deleteTutorial.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    deleteError: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default tutorialSlice.reducer;
export const { resetAddEditTutorials } = tutorialSlice.actions;


// Thunks
export const getTutorialsList = createAsyncThunk('tutorials/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getTutorialsList);
        ////console.log("tutorials slice check",data)
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addTutorial = createAsyncThunk('tutorials/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addTutorial, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
export const editTutorial = createAsyncThunk('tutorials/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.editTutorial, body);

        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});

export const deleteTutorial = createAsyncThunk('tutorial/delete', async (id, { rejectWithValue, fulfillWithValue }) => {
    // //console.log(id)
    try {
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deleteTutorial + `?tutorialId=${id}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
