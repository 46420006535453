import React from "react";
import { Button, Container } from "@material-ui/core";
import { Phone, PhoneDisabled } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    gridContainer: {
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    container: {
        width: "600px",
        margin: "35px 0",
        padding: 0,
        [theme.breakpoints.down("xs")]: {
            width: "80%",
        },
    },
    margin: {
        marginTop: 20,
    },
    padding: {
        padding: 20,
    },
    paper: {
        padding: "10px 20px",
        border: "2px solid black",
    },
}));

const Sidebar = ({ children, data }) => {
    const { callAccepted } = data;
    const { callEnded, leaveCall, callUser, userId } = data;
    const idToCall = userId;
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <div>
                {callAccepted && !callEnded ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<PhoneDisabled fontSize="large" />}
                        fullWidth
                        onClick={() => {
                            leaveCall(userId);
                        }}
                    >
                        Hang Up
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" startIcon={<Phone fontSize="large" />} fullWidth onClick={() => callUser(idToCall)}>
                        Call
                    </Button>
                )}
            </div>

            {children}
        </Container>
    );
};

export default Sidebar;
