const CryptoJS = require("crypto-js");

const cryptkey = CryptoJS.enc.Utf8.parse("e33b5c9c6c0998bc");

const cryptiv = CryptoJS.enc.Utf8.parse("NcRfUjWnZr4u7x");

// Encryption
// const data = JSON.stringify({ test: "123" });

module.exports = (data) => {
  var encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), cryptkey, {
    iv: cryptiv,
    mode: CryptoJS.mode.CTR,
  });
  const cipher = encrypt.toString();
  return { cipher }
};

// //console.log(encrypt.toString());
