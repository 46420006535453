import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const getRidesReducer = createSlice({
    name: 'getRides',
    initialState: {},
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserRides.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getUserRides.fulfilled, (state, action) => {

                return { ...state, loading: false, rides: action.payload }
            })
            .addCase(getUserRides.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
            builder
            .addCase(getRideDetails.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getRideDetails.fulfilled, (state, action) => {

                return { ...state, loading: false, ride: action.payload }
            })
            .addCase(getRideDetails.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
            builder
            .addCase(getAllRides.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getAllRides.fulfilled, (state, action) => {

                return { ...state, loading: false, rides: action.payload }
            })
            .addCase(getAllRides.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
      
    },
});

export default getRidesReducer.reducer;


// Thunks
export const getUserRides = createAsyncThunk('getUserRides/fetch', async (userId,userType,flag,{ rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getUserRides+`?userId=${userId}&userType=${userType}&flag=${flag}`);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

export const getRideDetails = createAsyncThunk('getRideDetails/fetch', async (rideId,flag='driver',{ rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getRideDetails+`?flag=${flag}&id=${rideId}`);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

export const getAllRides = createAsyncThunk('getAllRides/fetch', async ({flag,userType},{ rejectWithValue, fulfillWithValue }) => {
    // //console.log(flag,userType)
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAllRides+`?flag=${flag}&userType=${userType}`);
        // //console.log("rides",data)
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});


