import { Image } from "primereact/image";
import React from "react";
import appUrl from "../../../constants/appUrl";
import { formateDateTime } from "../../../services/date_service";

export default function ChatBubble({ chat, userId, fullName, permissions }) {
    const msgClasses = chat.sendBy !== userId ? "surface-200 px-5 py-3 border-round text-lg" : "bg-primary py-3 px-5 border-round text-lg";
    const wrapperClasses = chat.sendBy !== userId ? "flex flex-column align-items-end align-content-end mb-2" : "flex flex-column align-items-start align-content-start mb-2";
    return (
        <>
            <div className="flex flex-column my-3">
                <div className={wrapperClasses}>
                    <div className={msgClasses}>
                        <div>{chat.text}</div>
                        {chat.attachments.length > 0 && (
                            <div className="flex flex-row mt-3">
                                {chat.attachments.map((item) => (
                                    <Image key={item} preview className="mr-2" width="50px" height="60px" src={`${appUrl.baseUrl}${item}`} />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="font-bold">
                        {chat.sendBy === userId ? fullName : ""} <span className="text-xs">{formateDateTime(chat.createdAt)}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
