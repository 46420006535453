import * as consts from "../constants/rides_constants";

function getUserRidesReducer(state = {}, action) {
    switch (action.type) {
        case consts.GET_USER_RIDES_REQUEST:
            return { loading: true };
        case consts.GET_USER_RIDES_SUCCESS:
            return { loading: false, rides: action.payload.data, count: action.payload.count };
        case consts.GET_USER_RIDES_FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}
function getRideDetailsReducer(state = {}, action) {
    switch (action.type) {
        case consts.GET_RIDE_DETAILS_REQUEST:
            return { loading: true };
        case consts.GET_RIDE_DETAILS_SUCCESS:
            return { loading: false, ride: action.payload };
        case consts.GET_RIDE_DETAILS_FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}
function getAllRidesReducer(state = {}, action) {
    switch (action.type) {
        case consts.GET_ALL_RIDES_REQUEST:
            return { loading: true };
        case consts.GET_ALL_RIDES_SUCCESS:
            return { loading: false, rides: action.payload.data, count: action.payload.count };
        case consts.GET_ALL_RIDES_FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export { getUserRidesReducer, getRideDetailsReducer, getAllRidesReducer };
