import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const ModulesSlice = createSlice({
    name: "modules",
    initialState: {},
    reducers: {
        resetModulesList(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined, addSubModuleSuccess: false, editSubModuleSuccess: undefined, activeSubModuleSuccess: undefined };
        },
        resetSubModulesList(state, action) {
            return { ...state, addSubSuccess: undefined, editSubSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getModulesList.pending, (state, action) => {
                return { ...state, loading: true, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined, updateSuccess: undefined, activeSuccess: undefined };
            })
            .addCase(getModulesList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getModulesList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
        builder
            .addCase(addModules.pending, (state, action) => {
                return { ...state, addLoading: true, addSuccess: false };
            })
            .addCase(addModules.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addModules.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(editModule.pending, (state, action) => {
                return { ...state, editLoading: true, editSuccess: false };
            })
            .addCase(editModule.fulfilled, (state, action) => {
                return { ...state, editLoading: false, editData: action.payload, editSuccess: true };
            })
            .addCase(editModule.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSuccess: false,
                };
            });
        builder
            .addCase(activeDeActiveModule.pending, (state, action) => {
                return { ...state, editLoading: true, activeSuccess: false };
            })
            .addCase(activeDeActiveModule.fulfilled, (state, action) => {
                return { ...state, editLoading: false, activeSuccess: true };
            })
            .addCase(activeDeActiveModule.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSuccess: false,
                };
            });

        builder
            .addCase(addSubModules.pending, (state, action) => {
                return { ...state, addSubLoading: true, addSubModuleSuccess: false };
            })
            .addCase(addSubModules.fulfilled, (state, action) => {
                return { ...state, addSubLoading: false, addSubData: action.payload, addSubModuleSuccess: true };
            })
            .addCase(addSubModules.rejected, (state, action) => {
                return {
                    ...state,
                    addSubLoading: false,
                    addSubError: action.payload,
                    addSubModuleSuccess: false,
                };
            });

        builder
            .addCase(editSubModule.pending, (state, action) => {
                return { ...state, editLoading: true, editSubModuleSuccess: false };
            })
            .addCase(editSubModule.fulfilled, (state, action) => {
                return { ...state, editLoading: false, editData: action.payload, editSubModuleSuccess: true };
            })
            .addCase(editSubModule.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSubModuleSuccess: false,
                };
            });
        builder
            .addCase(activeDeActiveSubModule.pending, (state, action) => {
                return { ...state, editLoading: true, activeSubModuleSuccess: false };
            })
            .addCase(activeDeActiveSubModule.fulfilled, (state, action) => {
                return { ...state, editLoading: false, activeSubModuleSuccess: true };
            })
            .addCase(activeDeActiveSubModule.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSuccess: false,
                };
            });
    },
});

export default ModulesSlice.reducer;
export const { resetModulesList, resetSubModulesList } = ModulesSlice.actions;

// Thunks

export const getModulesList = createAsyncThunk("getModulesList/fetch", async (status, { rejectWithValue, fulfillWithValue }) => {
    try {
        // alert('hello')
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getModulesList);
        ////console.log('module data here' ,data)
        // //console.log("data",data.data[0].driver.firstName)
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const addModules = createAsyncThunk("addModules/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addModules, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const editModule = createAsyncThunk("editModule/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editModule, body);
        //console.log({response:data})
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const activeDeActiveModule = createAsyncThunk("activeDeActiveModule/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.activeModule, body);

        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const addSubModules = createAsyncThunk("addSubModules/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addSubModules, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const editSubModule = createAsyncThunk("editSubModule/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editSubModule, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const activeDeActiveSubModule = createAsyncThunk("activeDeActiveSubModule/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.activeSubModule, body);

        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
