import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const getUserRatingsSlice = createSlice({
    name: 'getUsersRatings',
    initialState: {},
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserRating.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getUserRating.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getUserRating.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

      
    },
});
export default getUserRatingsSlice.reducer;


// Thunks
export const getUserRating = createAsyncThunk('getUsersRatings/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.getUserRating,body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
