import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';
import decryptData from '../../app/features/services/decryptData';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const notifcationSlice = createSlice({
    name: 'notifcationSlice',
    initialState: {},
    reducers: {
        resetAddEditNotification(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationsList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getNotificationsList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
                
            })
            .addCase(getNotificationsList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
        builder
            .addCase(addNotification.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addNotification.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addNotification.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    error: action.payload,
                    addSuccess: false
                }
            });
        // builder
        //     .addCase(editNotification.pending, (state, action) => {
        //         return { ...state, editLoading: true }
        //     })
        //     .addCase(editNotification.fulfilled, (state, action) => {

        //         return { ...state, editLoading: false, editData: action.payload, addSuccess: true }
        //     })
        //     .addCase(editNotification.rejected, (state, action) => {

        //         return {
        //             ...state,
        //             editLoading: false,
        //             editError: action.payload,
        //             editSuccess: false
        //         }
        //     });
        // builder
        //     .addCase(deleteNotification.pending, (state, action) => {
        //         return { ...state, deleteLoading: true }
        //     })
        //     .addCase(deleteNotification.fulfilled, (state, action) => {

        //         return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
        //     })
        //     .addCase(deleteNotification.rejected, (state, action) => {

        //         return {
        //             ...state, deleteLoading: false,
        //             error: action.payload,
        //             deleteSuccess: false
        //         }
        //     });


    },
});

export default notifcationSlice.reducer;
export const { resetAddEditNotification } = notifcationSlice.actions;



// Thunks
export const getNotificationsList = createAsyncThunk('notifications/fetch', async (topic, { rejectWithValue, fulfillWithValue }) => {
    try {
        const  {data} = await axiosApi.get(appUrl.baseUrl + appUrl.getNotificationsList+`?topic=${topic}`);
        ////console.log("data",data);
        //return decryptData(data.cipher);
        fulfillWithValue(decryptData(data.cipher))
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

export const addNotification = createAsyncThunk('notifications/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addNotification, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});


// export const editNotification = createAsyncThunk('notifications/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
//     try {
//         const { data } = await Axios.put(appUrl.baseUrl + appUrl.editNotification, body);

//         return fulfillWithValue(data.data);
//     } catch (error) {

//         throw rejectWithValue(error.response && error.response.data.msg
//             ? error.response.data.msg
//             : error.message)

//     }

// });


// export const deleteNotification = createAsyncThunk('faq/delete', async (id, { rejectWithValue, fulfillWithValue }) => {
//     try {
//         const { data } = await Axios.delete(appUrl.baseUrl + appUrl.deleteNotification+`?FAQId=${id}`);
//         //console.log(data)
//         return fulfillWithValue(data.data);
//     } catch (error) {

//         throw rejectWithValue(error.response && error.response.data.msg
//             ? error.response.data.msg
//             : error.message)
//     }
// });


