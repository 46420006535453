export const GET_DRIVERS_LIST_REQUEST = 'GET_DRIVERS_LIST_REQUEST';
export const GET_DRIVERS_LIST_SUCCESS = 'GET_DRIVERS_LIST_SUCCESS';
export const GET_DRIVERS_LIST_FAILURE = 'GET_DRIVERS_LIST_FAILURE';


export const UPDATE_DRIVER_DOCUMENTS_STATUS_REQUEST = 'UPDATE_DRIVER_DOCUMENTS_STATUS_REQUEST';
export const UPDATE_DRIVER_DOCUMENTS_STATUS_SUCCESS = 'UPDATE_DRIVER_DOCUMENTS_STATUS_SUCCESS';
export const UPDATE_DRIVER_DOCUMENTS_STATUS_FAILURE = 'UPDATE_DRIVER_DOCUMENTS_STATUS_FAILURE';
export const UPDATE_DRIVER_DOCUMENTS_STATUS_RESET = 'UPDATE_DRIVER_DOCUMENTS_STATUS_RESET';




