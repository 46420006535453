import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const vehicleModelByIdSlice = createSlice({
    name: "vehicleModelById",
    initialState: {},
    reducers: {
        resetAddEditVehicleModels(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVehicleModelById.pending, (state, action) => {
                return { loading: true }
            })
            .addCase(getVehicleModelById.fulfilled, (state, action) => {
                return { loading: false, 
                    // data: [action.payload]
                    data: action.payload
                }
            })
            .addCase(getVehicleModelById.rejected, (state, action) => {
                return {
                    loading: false,
                    error: action.error.response && action.error.response.data.msg
                        ? action.error.response.data.msg
                        : action.error.message
                }
            });
    },
});
export default vehicleModelByIdSlice.reducer;
export const { resetAddEditVehicleModels } = vehicleModelByIdSlice.actions;

// Thunks
export const getVehicleModelById = createAsyncThunk('getVehicleModelById/fetch', async ({modelId}, { rejectWithValue, fulfillWithValue }) => {
    try {
        // //console.log('vehicle modle id',modelId)
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getVehicleModelById + `?modelId=${modelId}`);
        // //console.log("vehicle model by id",data)
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
