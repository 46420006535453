import moment from 'moment';

const formatDate=(value)=>{
    return moment(value).format('DD-MMM-YYYY');
}

const formateDateTime=(value)=>{
    return moment(value).format('DD-MMM-YYYY hh:mm a');
}

const formatTime=(value)=>{
    return moment(value).format('hh:mm n');
}

export {formatDate,formateDateTime,formatTime};