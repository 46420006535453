/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import VTypeDialoge from "../../vehiclemanagement/components/vehicle-type-dailoge";
import { confirmDialog } from "primereact/confirmdialog";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useDispatch, useSelector } from "react-redux";
import CustomLoading from "../../../components/custom_loading";
import { deleteVehicleType, getVehicleType, resetAddEditVehicleType } from "../../../../../redux/slices/vehicle_type_slice";
import { Dropdown } from "primereact/dropdown";
export const vehicleType = ({ permissions }) => {
    //redux
    const { data: list, loading, deleteError, deleteTypeSuccess } = useSelector((state) => state.vehicleType);
    const dispatch = useDispatch();

    //hooks
    const [editUser, setEditUser] = useState(null);
    const [AddType, setAddType] = useState(false);
    const [status, setStatus] = useState(0);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const statusList = [
        {
            status: "Active",
            value: 0,
        },
        {
            status: "Deleted",
            value: 1,
        },
    ];

    useEffect(() => {
        if (list === undefined) {
            dispatch(getVehicleType());
        }
    }, []);
    // dialog
    const dialogFuncMap = {
        AddType: setAddType,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const activeStatusBody = (rowData) => {
        // return <div className={rowData.active.status ? "green" : "red"}>{rowData.active.status ? "Active" : "In Active"}</div>;
    };
    let deleteId;
    const accept = () => {
        let data = {
            typeId: deleteId,
        };
        // //console.log(data)
        dispatch(deleteVehicleType(data));
    };
    const confirmDeleteAd = () => {
        confirmDialog({
            message: "Do you want to delete this vehicle type?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="">
                {permissions?.add && (
                    <Button
                        tooltip=""
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("AddType");
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
                {permissions?.delete && (
                    <Button
                        tooltip="Delete"
                        icon="pi pi-trash"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            deleteId = rowData._id;
                            // //console.log(deleteId)
                            confirmDeleteAd();
                        }}
                        className="edit mr-2 p-button-danger p-button-rounded"
                    />
                )}
            </div>
        );
    };

    const typeTemp = (rowData) => {
        // const capital = rowData.type.toUpperCase();
        const capital = rowData.type.charAt(0).toUpperCase() + rowData.type.slice(1).toLowerCase();
        return <div>{capital}</div>;
    };
    useEffect(() => {
        if (deleteTypeSuccess !== undefined) {
            if (deleteTypeSuccess === true) {
                toast.success("Vehicle Type deleted");
                dispatch(getVehicleType());
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetAddEditVehicleType());
        };
    }, [deleteTypeSuccess, deleteError]);

    return (
        <>
            {loading === true || list === undefined ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <Dialog header={editUser == null ? "Add Vehicle Types" : "Update Vehicle Types"} visible={AddType} onHide={() => onHide("AddType")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }} className="Modal_class">
                        <VTypeDialoge permissions={permissions} editUser={editUser} onHide={() => onHide("AddType")} />
                    </Dialog>

                    <div className="grid pb-3">
                        <div className="col-12 md:col-6">
                            <h4>Vehicle type</h4>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="mb-9 text-right">
                                <span className="p-input-icon-right mr-3">
                                    {/* <Dropdown style={{ width: "215px" }} className="mr-3 text-left" options={statusList} optionLabel="status" optionValue="value" value={status} onChange={(e) => setStatus(e.value)} placeholder="Select Vehicle Makers" /> */}
                                    <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                                </span>
                                {permissions?.add && (
                                    <Button
                                        label="Add new"
                                        className="p-button-success p-button-rounded p-button-sm custom___width"
                                        onClick={() => {
                                            setEditUser(null);
                                            onClick("AddType");
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <DataTable className="p-datatable-gridlines" filters={filters} rows={50} paginator responsiveLayout="scroll" emptyMessage="No record found." value={list} loading={loading} globalFilterFields={["type"]}>
                        <Column body={typeTemp} header="Vehicle Type" />
                        {/* <Column body={activeStatusBody} header="Vehicle Type" /> */}
                        <Column header="Action" body={actionTemplate} className="text-center Action_col" />
                    </DataTable>
                </div>
            )}
        </>
    );
};
