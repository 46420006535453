import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { resetPassword } from "../../../../../redux/slices/reset_password_slice";

export default function ResetPassword({ onHide, editUserData, permissions }) {
    // const getUserReducer = useSelector((state) => state.getUser);
    // const {roles,loading, addSuccess, addLoading, addError, editSuccess, editLoading, editError} = getUserReducer;

    const dispatch = useDispatch();
    // //console.log(editUserData)
    // const loadEditData = () => {
    //     // formik.setFieldValue("status", editUser['status']);
    //     formik.setFieldValue("userName", editUser["userName"]);
    //     formik.setFieldValue("email", editUser["email"]);
    //     formik.setFieldValue("mobile", editUser["mobile"]);
    //     formik.setFieldValue("status", editUser["status"]);
    //     formik.setFieldValue("role", editUser["role"]["_id"]);
    // };

    // useEffect(() => {
    //     if (roles === undefined || roles.length === 0) {
    //         dispatch(getUserRoles());
    //     }
    //     if (editUser != null) {
    //         loadEditData();
    //     }
    // }, []);

    // useEffect(() => {

    //     if (addSuccess !== undefined) {
    //         if (addSuccess === true) {
    //              toast.success("User sccessfully added");
    //             formik.resetForm();
    //             onHide();
    //             dispatch(getAdminUsers());
    //         } else {
    //             toast.error(addError)
    //         }
    //     }

    //     if (editSuccess !== undefined) {
    //         if (editSuccess === true) {
    //              toast.success("User successfully updated");
    //             formik.resetForm();
    //             onHide();
    //             dispatch(getAdminUsers());
    //         } else {
    //              toast.error(editError)
    //         }
    //     }
    //     return () => {
    //         dispatch(resetAdminUser());
    //     };
    // }, [addSuccess, addError, editSuccess, editError]);

    const validationSchema = Yup.object().shape({
        password: editUserData == null ? Yup.string().required("This field is required.").min(8, "Minimum length should be 8") : Yup.string().min(8, "Minimum length should be 8"),
        mobile: Yup.number()
            .test("valid-phone-number", "Invalid phone number", (value) => {
                const phoneRegExp = /^92[ -]?3[0-9]{2}[ -]?[0-9]{7}$/;
                return value !== undefined && phoneRegExp.test(value.toString());
            })
            .required("Phone number is required"),
        // Yup.number().required("This field is required."),
        // Yup.string()
        //     .transform((value) => (value ? value.replace(/\D/g, '') : ''))
        //     .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
        //     .required('Phone number is required'),
        // .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
        // .max(100, "Maximum length 100 allowed")
        // .nullable(),
        // email: Yup.string().email("Invalid email address format").required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            password: "",
            mobile: "",
            // email: "",
        },

        onSubmit: async (data) => {
            // //console.log(editUserData)
            // //console.log(data)
            dispatch(resetPassword(data));
            // if (editUserData == null) {
            //     // //console.log(editUserData)

            //     //console.log(data)

            // }
            // else {
            //     data["userId"] = editUser._id;
            //     dispatch(editAdminUser(data));
            // }
            // setLoading(true);
            // setloadingIcon("pi pi-spin pi-spinner");
            // if (editable === true) {

            //     data["userId"] = UsersRowData;
            //     const res = await dispatch(handlePatchRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // } else {
            //     const res = await dispatch(handlePostRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // }
            // setLoading(false);
            // setloadingIcon("pi pi-save");
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <div className="p-fluid grid">
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <CustomInputField iden={"mobile"} formik={formik} type="number" placeHolder={"920000000000"} />
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <CustomInputField iden={"password"} formik={formik} type="password" placeHolder="Please enter a password" feedback={false} />
                                </div>
                            </div>
                            <div className="col-12 md:col-12">
                                <div className="button_class-modal">
                                    {permissions?.edit && (
                                        <Button
                                            // loading={editUser == null ? addLoading : editLoading}
                                            type="submit"
                                            label={editUserData === null ? "Add" : "Update"}
                                            className="p-button-success btn__default_modal p-button-rounded"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
