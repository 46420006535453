const CryptoJS = require("crypto-js");

const cryptkey = CryptoJS.enc.Utf8.parse("e33b5c9c6c0998bc");

const cryptiv = CryptoJS.enc.Utf8.parse("NcRfUjWnZr4u7x");

// Encryption
// const data = JSON.stringify({ test: "123" });

module.exports = (data) => {
    const crypted = CryptoJS.enc.Base64.parse(data.toString()); //"Zt8VfHQqiKj/MToZGwWppw==");

    var decrypt = CryptoJS.AES.decrypt({ ciphertext: crypted }, cryptkey, {
        iv: cryptiv,
        mode: CryptoJS.mode.CTR,
    });
    const data2 = decrypt.toString(CryptoJS.enc.Utf8);
    if (data2) return JSON.parse(data2);
    return null;
};
