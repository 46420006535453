import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomLoading from '../../../components/custom_loading';
import { useEffect } from 'react';
import { getUserById } from '../../../../../redux/slices/users_slice';
import { useState } from 'react';

function ProfileEditDialog({ userId, editUserData, permissions }) {
  //redux
  const { usersLoading: loading , user } = useSelector((state) => state.usersList);
  // const [editUser, setEditUser ] =useState("hi")
  // console.log(editUser)

  
  // function EditUser (){
  //   setEditUser(console.log("function works"))
  // }
  // EditUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserById({ userId, isDriver: true }));
    // //console.log(userId)
    return () => {};
  }, []);

  return (
    <>
      {loading === true || user === undefined ? (
        <CustomLoading />
      ) : (
        <section className=' '>
          <div className="flex flex-column align-items-center gap-2 w-full mb-3">
            <label htmlFor="username">Username</label>
            <InputText id="username" value={user?.firstName}  aria-describedby="username-help" />
          </div>
          <div className="flex flex-column align-items-center gap-2 w-full mb-3">
            <label htmlFor="mobile">Mobile Number</label>
            <InputText id="mobile" value={user?.mobile} aria-describedby="username-help" />
          </div>
          <div className="flex flex-column align-items-center gap-2 w-full mb-5">
            <label htmlFor="cnic">CNIC</label>
            <InputText id="cnic" value={user?.cnic} aria-describedby="username-help" />
          </div>
          <Button label='Submit' />
        </section>
          )}  
    </>
  )
}

export default ProfileEditDialog
