import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const SmsLogsReducer = createSlice({
    name: "smslLogs",
    initialState: {},
    reducers: {
        resetChangeStatus(state, action) {
            return { ...state, changeSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSmsLogs.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getSmsLogs.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload.data, count: action.payload.count, today: action.payload.today, thisMonth: action.payload.thisMonth };
            })
            .addCase(getSmsLogs.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
    },
});

export default SmsLogsReducer.reducer;
export const { resetChangeStatus } = SmsLogsReducer.actions;

// Thunks
// department Get Thunk
export const getSmsLogs = createAsyncThunk("logs/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getSmsLogs + `?startDate=${body.startDate}&endDate=${body.endDate}`);
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
