export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';


export const GET_ADMIN_USERS_REQUEST = 'GET_ADMIN_USERS_REQUEST';
export const GET_ADMIN_USERS_SUCCESS = 'GET_ADMIN_USERS_SUCCESS';
export const GET_ADMIN_USERS_FAILURE = 'GET_ADMIN_USERS_FAILURE';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';


export const ADD_ADMIN_USER_REQUEST = 'ADD_ADMIN_USER_REQUEST';
export const ADD_ADMIN_USER_SUCCESS = 'ADD_ADMIN_USER_SUCCESS';
export const ADD_ADMIN_USER_FAILURE = 'ADD_ADMIN_USER_FAILURE';
export const RESET_ADD_ADMIN_USER = 'RESET_ADD_ADMIN_USER';

export const EDIT_ADMIN_USER_REQUEST = 'EDIT_ADMIN_USER_REQUEST';
export const EDIT_ADMIN_USER_SUCCESS = 'EDIT_ADMIN_USER_SUCCESS';
export const EDIT_ADMIN_USER_FAILURE = 'EDIT_ADMIN_USER_FAILURE';
export const RESET_EDIT_ADMIN_USER = 'RESET_ADMIN_USER';