import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserRides } from "../../../../../redux/actions/rides_actions";
import RideTableComponent from "./ride_table_component";
import { useState } from "react";
export default function PassengerHistoryTab({ userId, userType, permissions }) {
    const [pageNumber, setPageNumber] = useState(0);
    //redux
    const getUserRidesReducer = useSelector((state) => state.getUserRides);
    const { rides, loading, count } = getUserRidesReducer;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserRides(userId, userType, "history", pageNumber));
    }, [pageNumber]);

    //properties

    //methods

    return (
        <>
            <RideTableComponent permissions={permissions} rides={rides} loading={loading} userType={userType} count={count} setPageNumber={setPageNumber} />
        </>
    );
}
