import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import DriverActiveTab from "../components/driver_active_tab";
import DriverOtherTab from "../components/driver_other_tab";
import DriverHistoryTab from "../components/driver_history_tab";
import { useParams } from "react-router-dom";

export default function OwnerRideScreen({ props }) {
    const param = useParams();
    const { userId } = param;

    return (
        <div className="card">
            <h3>Schedules and Rides</h3>
            <TabView>
                <TabPanel header="Schedules">
                    <TabView>
                        <TabPanel header="Active">{<DriverActiveTab permissions={props?.permissions} userId={userId} userType="1" />}</TabPanel>
                        <TabPanel header="Other">{<DriverOtherTab permissions={props?.permissions} userId={userId} userType={"1"} />}</TabPanel>
                    </TabView>
                </TabPanel>
                <TabPanel header="Rides">
                    <TabView>
                        <TabPanel header="History">{<DriverHistoryTab permissions={props?.permissions} userId={userId} userType={"1"} />}</TabPanel>
                    </TabView>
                </TabPanel>
            </TabView>
        </div>
    );
}
