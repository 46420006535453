import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import TicketComponent from "./ticket_component";
// import { SocketContext } from "../../../../../SocketProvider";
import CustomLoading from "../../../components/custom_loading";
import encryptData from "../../../services/encryptData";
import decryptData from "../../../services/decryptData";
import { SocketContext } from "../../../../../Context";

export default function ResolvedSupportRequestsScreen({ status, permissions }) {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const { socket } = useContext(SocketContext);

    useEffect(() => {
        if (page == 0) setLoading(true);
        setTimeout(() => {
            socket.emit("allTickets", JSON.stringify(encryptData({ page, flag: "close" })), (data) => {
                setLoading(false);
                setLoadingMore(false);
                data = decryptData(data?.cipher);
                //console.log(data);
                setTickets((tickets) => [...tickets, ...data?.data]);
            });

            socket.on("newTicket", (data) => {
                socket.emit("allTickets", JSON.stringify(encryptData({ page: 0, flag: "close" })), (data) => {
                    setLoading(false);
                    setLoadingMore(false);
                    data = decryptData(data?.cipher);
                    //console.log(data);
                    setTickets((tickets) => [...tickets, ...data?.data]);
                });
            });

            return () => {
                socket.off("newTicket");
            };
        }, 3000);
    }, [page]);

    const handleLoadMore = () => {
        setLoadingMore(true);
        setPage((page) => page + 1);
    };

    return (
        <>
            {loading ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <div className="flex flex-row justify-content-between align-content-center align-items-center pb-3">
                        <h5>Tickets</h5>
                    </div>
                    {tickets.map((item) => (
                        <TicketComponent permissions={permissions} key={item._id} ticket={item} status={status} />
                    ))}
                    {tickets.length < (page + 1) * 10 ? null : <div className="text-center">{loadingMore ? <CustomLoading /> : <Button label="Load More" onClick={handleLoadMore} />}</div>}
                </div>
            )}
        </>
    );
}
