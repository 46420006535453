import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const vehicleListByIdSlice = createSlice({
    name: 'vehicleListByIdSlicee',
    initialState: {},
    reducers: {
        resetVehicleList(state, action) {
            return { ...state, updateSuccess: undefined}
        }
    },
    
    extraReducers: (builder) => {
            

            builder
            .addCase(getUserVehiclesById.pending, (state, action) => {
                return { ...state, usersLoading: true };
            })
            .addCase(getUserVehiclesById.fulfilled, (state, action) => {
                
                return { ...state, usersLoading: false, data: action.payload };
            })
            .addCase(getUserVehiclesById.rejected, (state, action) => {
                return {
                    ...state,
                    usersLoading: false,
                    usersError: action.payload,
                };
            });
      
    },
});

export default vehicleListByIdSlice.reducer;
export const { resetVehicleList } = vehicleListByIdSlice.actions;



// Thunks

export const getUserVehiclesById = createAsyncThunk("getVehicleListById/get", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        // //console.log("userId",userId)
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.getUserVehiclesById,body);
        // //console.log("user data vehicles",data?.data)
        return fulfillWithValue([data?.data]);

    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

