import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomInputField from "../../../components/custom_input_filed";
import { addSubModules, editSubModule, getModulesList, resetModulesList } from "../../../../../redux/slices/modules_slice";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function AddEditSubModule({ editData, onHide, permissions }) {
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name field is required."),
        icon: Yup.string().required("Icon field is required."),
        route: Yup.string().required("Route field is required."),
        module: Yup.string().required("Module field is required."),
    });
    const modulesListReducer = useSelector((state) => state.modulesList);

    const { data, addSubSuccess, editSubModuleSuccess } = modulesListReducer;
    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            icon: "",
            route: "",
            module: "",
            active: "",
        },

        onSubmit: async (data) => {
            if (editData == null) {
                dispatch(addSubModules(data));
            } else {
                data["subModuleId"] = editData._id;
                dispatch(editSubModule(data));
            }
        },
    });

    useEffect(() => {
        return () => {
            dispatch(getModulesList());
        };
    }, []);

    useEffect(() => {
        if (addSubSuccess !== undefined) {
            if (addSubSuccess === true) {
                toast.success("Sub Module successfully added");
                onHide();
                dispatch(getModulesList());
            } else {
                // toast.error(addError);
            }
        }
        if (editSubModuleSuccess !== undefined) {
            if (editSubModuleSuccess === true) {
                toast.success("Sub Module successfully updated");
                onHide();
                dispatch(getModulesList());
            } else {
                // toast.error(editError);
            }
        }
        return () => {
            dispatch(resetModulesList());
        };
    }, [addSubSuccess, editSubModuleSuccess]);

    useEffect(() => {
        if (editData !== null) {
            loadValues();
        }
    }, []);

    const loadValues = () => {
        formik.setFieldValue("name", editData.name);
        formik.setFieldValue("icon", editData.icon);
        formik.setFieldValue("route", editData.route);
        formik.setFieldValue("module", editData.module);
        formik.setFieldValue("orderPosition", editData.orderPosition);
        formik.setFieldValue("active", editData.active);
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className=" p-fluid grid">
                        <div className="col-12 md:col-6">
                            <CustomInputField type="dropdown" iden="module" label="Module" name="module" options={data} formik={formik} optionLabel="name" optionValue="_id" placeHolder="Please Select" />
                        </div>
                        <div className="col-12 md:col-6">
                            <CustomInputField iden="name" label="Name" formik={formik} />
                        </div>
                        <div className="col-12 md:col-6">
                            <CustomInputField iden="icon" label="Icon" formik={formik} />
                        </div>
                        <div className="col-12 md:col-6">
                            <CustomInputField iden="route" label="Route" formik={formik} />
                        </div>
                        {editData != null && (
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <CustomInputField iden={"active"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                                </div>
                            </div>
                        )}
                        <div className="col-12">
                            <div className="button_class-modal">
                                {permissions?.add && editData == null && (
                                    <Button
                                        // loading={editData == null ? addLoading : editLoading}
                                        type="submit"
                                        label={editData === null ? "Add" : "Update"}
                                        className="p-button-success btn__default_modal p-button-rounded"
                                    />
                                )}
                                {permissions?.edit && editData && (
                                    <Button
                                        // loading={editData == null ? addLoading : editLoading}
                                        type="submit"
                                        label={editData === null ? "Add" : "Update"}
                                        className="p-button-success btn__default_modal p-button-rounded"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
