import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import CustomLoading from "../../../../components/custom_loading";

export default function AddEditSubjectDialog({ editUser, permissions }) {
    const { addLoading, editLoading, addSuccess, editSuccess } = useSelector((state) => state.getNotificationsList);
    const [value1, setValue1] = useState("");
    const getRoles = useSelector((state) => state.getUserRoles);
    const { roles, loading } = getRoles;

    const toast = useRef(null);

    const statusList = [
        {
            code: true,
            name: "active",
        },
        {
            code: false,
            name: "block",
        },
    ];

    useEffect(() => {}, []);

    useEffect(() => {}, [addSuccess, editSuccess]);

    const validationSchema = Yup.object().shape({
        userName: Yup.string().required("This field is required."),
        password: editUser == null ? Yup.string().required("This field is required.").min(8, "Minimum length should be 8") : Yup.string().min(8, "Minimum length should be 8"),
        email: Yup.string().email("Invalid email address format").required("This field is required."),
        mobile: Yup.string()
            .required("This field is required.")
            .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
            .max(100, "Maximum length 100 allowed")
            .nullable(),
        role: Yup.string().required("Role is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            userName: "",
            password: "",
            email: "",
            mobile: "",
            role: "",
            status: "",
        },

        onSubmit: async () => {},
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Toast ref={toast} />

            <form onSubmit={formik.handleSubmit}>
                {loading === true ? (
                    <CustomLoading />
                ) : (
                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">
                            <div className="field">
                                <label htmlFor="name1">Class</label>
                                <InputText id="userName" name="userName" value={formik.values.userName} onChange={formik.handleChange} type="text" className={classNames({ "p-invalid": isFormFieldValid("userName") })} />
                                {getFormErrorMessage("userName")}
                            </div>
                            <div className="field">
                                <label htmlFor="name1"> Message</label>
                                <InputTextarea value={value1} onChange={(e) => setValue1(e.target.value)} rows={3} />
                            </div>
                            <div className="field">
                                <label htmlFor="age1">Recipents</label>
                                <Dropdown optionLabel="role" name="role" optionValue="_id" value={formik.values.role} options={roles} onChange={formik.handleChange} placeholder="Select a role" className={classNames({ "p-invalid": isFormFieldValid("role") })} />
                                {getFormErrorMessage("role")}
                            </div>

                            {editUser != null && (
                                <div className="field">
                                    <label htmlFor="age1">Status</label>
                                    <Dropdown optionLabel="name" optionValue="code" name="status" value={formik.values.status} options={statusList} onChange={formik.handleChange} placeholder="Select a status" className={classNames({ "p-invalid": isFormFieldValid("role") })} />
                                </div>
                            )}
                            {permissions?.add && editUser == null && <Button loading={editUser == null ? addLoading : editLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success" />}
                            {permissions?.edit && editUser && <Button loading={editUser == null ? addLoading : editLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success" />}
                        </div>
                    </div>
                )}
            </form>
        </>
    );
}
