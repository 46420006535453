import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const faqCategorySlice = createSlice({
    name: 'faqCategorySlice',
    initialState: {},
    reducers: {
        resetAddEditFaqCategory(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFaqsCategoryList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getFaqsCategoryList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getFaqsCategoryList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addFaqsCategory.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addFaqsCategory.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addFaqsCategory.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(editFaqsCategory.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editFaqsCategory.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editFaqsCategory.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(deleteFaqsCategory.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteFaqsCategory.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(deleteFaqsCategory.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default faqCategorySlice.reducer;
export const { resetAddEditFaqCategory } = faqCategorySlice.actions;



// Thunks
export const getFaqsCategoryList = createAsyncThunk('faq/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getFaqsCategoryList);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addFaqsCategory = createAsyncThunk('faq/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addFaqsCategory, body);
        return fulfillWithValue(data.data);
        
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editFaqsCategory = createAsyncThunk('faq/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editFaqsCategory, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
export const deleteFaqsCategory = createAsyncThunk('faq/delete', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deleteFaqsCategory + `?categoryId=${body.categoryId}`);
        
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
