import { getAccountsList } from "../../../redux/slices/accounts_slice";

const appUrl = {
    // baseUrl: "http://127.0.0.1:3003/",
    // fileBaseUrl: "http://127.0.0.1:3003/",
    // socketBaseUrl: "http://127.0.0.1:3003",
    baseUrl: "https://qa.rahper.com/",
    fileBaseUrl: "https://qa.rahper.com/",
    socketBaseUrl: "https://qa.rahper.com",
    // baseUrl: "https://live.rahper.com/",
    // fileBaseUrl: "https://live.rahper.com/",
    // socketBaseUrl: "https://live.rahper.com",
    // baseUrl: 'http://dev.rahper.com/',
    // fileBaseUrl: 'http://dev.rahper.com/',
    adminLogin: "api/web/login",
    //reset password
    resetAdminUserPassword: "api/web/resetPassword",
    getDriversList: "api/web/user/userByType",
    getPassengersList: "api/web/user/userByType",
    updateUserStatus: "api/web/user/activeStatus",
    updateDriverDocumentStatus: "api/user/updateDocumentStatus",
    getUserById: "api/web/user/userProfile",
    getUserRides: "api/web/rides",
    getRideDetails: "api/web/rides/rideById",
    getAllRides: "api/web/rides/all",
    //dashboard
    getDashboardData: "api/web/dashboard/stat",
    getDashboardStat: "api/newDashboard/stat",
    //dashboard
    getAllRoles: "api/web/role/all",
    addAdminUser: "api/web/adminUser",
    getAdminUsers: "api/web/all",
    getAdminUserById: "api/web/byId",
    updateProfileImage: "api/web/profileImage",
    editAdminUser: "api/web/adminUser",
    getFares: "api/web/fareInials",
    updateFares: "api/web/fareInials",
    getUnverifiedDrivers: "api/web/user/unverifiedDrivers",
    getVerifiedDrivers: "api/web/user/verifiedDrivers",
    getUserDocumentsById: "api/user/userDocuments",
    getReceiptDetails: "api/paymentHistory/receiptBySchedule",
    getCorporateCodesList: "api/corporate/all",
    addCorporateCode: "api/corporate",
    editCorporateCode: "api/corporate/updateFee",
    getAdsList: "api/ads",
    addAds: "api/promotion",
    editAds: "api/promotion",
    deleteAds: "api/promotion",
    activeRideLastLocatins: "api/web/rides/startedRoutesLastLocations",
    getVersionControl: "api/web/appVersion/list",
    updateVersionControl: "api/web/appVersion",
    getFaqsList: "api/FAQ",
    addFaqs: "api/FAQ",
    editFaqs: "api/FAQ",
    deleteFaqs: "api/FAQ",
    getFaqsCategoryList: "api/FAQCategory",
    addFaqsCategory: "api/FAQCategory",
    editFaqsCategory: "api/FAQCategory",
    deleteFaqsCategory: "api/FAQCategory",
    getUserRating: "api/rating/userRatings",
    getNotificationsList: "api/systemNotification/topic",
    addNotification: "api/systemNotification",
    getVehicleDocumentsList: "api/vehicle",
    updateVehicleDocumentStatus: "api/vehicle/status",
    //Vechile Management
    getVehicleType: "api/vehicletype/list",
    getVehicleMaker: "api/vehiclemake/list",
    getVehicleModelById: "api/vehicleModel",
    vehicleModel: "api/vehiclemodel/list",
    addVehicleType: "api/vehicletype",
    addVehicleMaker: "api/vehiclemake",
    addVehicleModels: "api/vehiclemodel",
    updateVehicleType: "api/vehicleType",
    updateVehicleMake: "api/vehicleMake",
    updateVehicleModels: "api/vehicleModel",
    deleteVehicleType: "api/vehicleType",
    deleteVehicleMake: "api/vehicleMake",
    deleteVehicleModel: "api/vehicleModel",
    getUserVehiclesById: "api/user/getuservehiclelist",
    // Tutorials End Points
    getTutorialsList: "api/web/tutorial",
    addTutorial: "api/web/tutorial",
    editTutorial: "api/web/tutorial",
    deleteTutorial: "api/web/tutorial",
    // Tutorials End Points

    //Tutorials category Endpoints
    getTutorialsCategoryList: "api/web/tutorialCategory",
    addTutorialsCategory: "api/web/tutorialCategory",
    updateTutorialsCategory: "api/web/tutorialCategory",
    deleteTutorialsCategory: "api/web/tutorialCategory",
    //Tutorials category Endpoints
    //Accounts End point
    getAccountsList: "api/paymentHistory",
    //Accounts End point
    //Modules Management Endpoints
    getModulesList: "api/web/module",
    addModules: "api/web/module",
    addSubModules: "api/web/subModule",
    //Modules Management Endpoints
    addRoles: "api/web/role",
    updateRoles: "api/web/role",
    editModule: "api/web/module",
    editSubModule: "api/web/subModule",
    activeModule: "api/web/module/activeDeActive",
    activeSubModule: "api/web/subModule/activeDeActive",
    roleModulesList: "api/web/role/modules",

    //permission
    roleModulePermission: "api/web/permission/byRole",
    addRoleModulePermission: "api/web/permission",
    //Roles Managemnet

    //passowrd
    resetPassword: "api/user/resetpassword",
    //referrer
    getreferrrerList: "api/web/user/referAndReferBy",
    getGoogleAddress: "api/web/googleMap",
    //sms logs
    getSmsLogs: "api/web/smsLog",
    //blocked users
    getBlockedUserList: "api/web/user/blocked",
    getModulesPermissions: "api/web/permission/byRole/",
};
export default appUrl;
