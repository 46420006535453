import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRides } from "../../../../../redux/actions/rides_actions";
import AllRidesTableComponent from "./all_rides_table_component";
export default function AllRidesComponents({ flag, userType, permissions }) {
    const getAllRidesReducer = useSelector((state) => state.getAllRides);
    const { rides, loading, count } = getAllRidesReducer;
    const [pageNumber, setPageNumber] = useState(0);
    const [rows, setRows] = useState(50);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllRides(flag, userType, pageNumber, rows));
    }, [flag, userType, pageNumber, rows]);
    return (
        <>
            <AllRidesTableComponent rows={rows} setRows={setRows} permissions={permissions} rides={rides} userType={userType} loading={loading} count={count} setPageNumber={setPageNumber} flag={flag} />
        </>
    );
}
