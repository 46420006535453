import * as consts from "../constants/admin_user_constants";


function loginUserReducer(state = {}, action) {
    switch (action.type) {
      case consts.LOGIN_USER_REQUEST:
        return { loading: true };
      case consts.LOGIN_USER_SUCCESS:
        return { loading: false, user: action.payload ,success:true};
      case consts.LOGIN_USER_FAILURE:
        return { loading: false, error: action.payload,success:false };
      case consts.LOGOUT_USER:
        localStorage.removeItem('user');
        return { };
      default: return state;
    }
  }

  function getAdminUsersReducer(state = {adminUsers:[]}, action) {
    switch (action.type) {
      case consts.GET_ADMIN_USERS_REQUEST:
        return { loading: true };
      case consts.GET_ADMIN_USERS_SUCCESS:
        return { loading: false, adminUsers: action.payload };
      case consts.GET_ADMIN_USERS_FAILURE:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function addAdminUserReducer(state = {}, action) {
    switch (action.type) {
      case consts.ADD_ADMIN_USER_REQUEST:
        return { loading: true };
      case consts.ADD_ADMIN_USER_SUCCESS:
        return { loading: false, user: action.payload ,success:true};
      case consts.ADD_ADMIN_USER_FAILURE:
        return { loading: false, error: action.payload,success:false };
        case consts.RESET_ADD_ADMIN_USER:
          return {};
      default: return state;
    }
  }
  function editAdminUserReducer(state = {}, action) {
    switch (action.type) {
      case consts.EDIT_ADMIN_USER_REQUEST:
        return { loading: true };
      case consts.EDIT_ADMIN_USER_SUCCESS:
        return { loading: false, user: action.payload ,success:true};
      case consts.EDIT_ADMIN_USER_FAILURE:
        return { loading: false, error: action.payload,success:false };
        case consts.RESET_EDIT_ADMIN_USER:
          return {};
      default: return state;
    }
  }

  function getRolesReduces(state = {roles:[]}, action) {
    switch (action.type) {
      case consts.GET_ROLES_REQUEST:
        return { loading: true };
      case consts.GET_ROLES_SUCCESS:
        return { loading: false, roles: action.payload };
      case consts.GET_ROLES_FAILURE:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }
  export {loginUserReducer,getAdminUsersReducer,addAdminUserReducer,editAdminUserReducer,getRolesReduces}


