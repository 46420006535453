import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const referrerReducer = createSlice({
    name: "referrer",
    initialState: {},
    reducers: {
        resetUpdateVersion(state, action) {
            return { ...state, updateSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getreferrrerList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getreferrrerList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getreferrrerList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });

       
    },
});

export default referrerReducer.reducer;
export const { resetUpdateVersion } = referrerReducer.actions;

// Thunks
export const getreferrrerList = createAsyncThunk("referrer/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getreferrrerList);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

