import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import AddEditSubjectDialog from "./add_edit_subject_dialog";

export default function SubjectScreen({ permissions }) {
    const [editUser] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    useEffect(() => {}, []);

    const statusBodyTemplate = (rowData) => {
        return <span className={rowData.status ? "green" : "red"}>{rowData.status ? "Active" : "Inactive"}</span>;
    };

    const actionsBodyTemplate = () => {
        return <div>{permissions?.edit && <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={() => {}} aria-label="Edit" />}</div>;
    };
    return (
        <>
            <Dialog header={editUser == null ? "Add Notification" : "Update Notification"}>
                <AddEditSubjectDialog permissions={permissions} editUser={editUser} />
            </Dialog>
            <div className="card">
                <div className="flex flex-row justify-content-between align-content-center align-items-center pb-3">
                    <div>
                        <h5>Subject</h5>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                                <InputText className="mr-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                        </div>
                        {permissions?.add && (
                            <Button
                                label="Add new"
                                className="p-button-success p-button-rounded p-button-sm"
                                onClick={() => {
                                    // setEditUser(null);
                                    // onClick('showAddUserDialog')
                                }}
                            />
                        )}
                    </div>
                </div>
                {/* className="p-datatable-gridlines" showGridlines */}
                <DataTable
                    //value={adminUsers}
                    paginator
                    rows={10}
                    dataKey="id"
                    filterDisplay="menu"
                    //loading={loading}
                    responsiveLayout="scroll"
                    emptyMessage="No record found."
                    filters={filters}
                    globalFilterFields={["userName", "email", "mobile", "role.role"]}
                >
                    <Column field="userName" header="Username" filter filterPlaceholder="Search by name" style={{ minWidth: "12rem" }} />
                    <Column field="email" header="Email" filter filterPlaceholder="Search by email" style={{ minWidth: "12rem" }} />
                    <Column field="mobile" header="Mobile" filter filterPlaceholder="Search by mobile" style={{ minWidth: "12rem" }} />
                    <Column field="role.role" header="Role" filter filterPlaceholder="Search by role" style={{ minWidth: "12rem" }} />

                    {/* <Column header="Date" field="createdAt" dataType="date" style={{ minWidth: '10rem' }}
                        filter /> */}
                    <Column field="status" header="Status" filterMenuStyle={{ width: "14rem" }} style={{ minWidth: "12rem" }} body={statusBodyTemplate} filter />
                    <Column header="Actions" filterMenuStyle={{ width: "14rem" }} style={{ minWidth: "12rem" }} body={actionsBodyTemplate} />
                </DataTable>
            </div>
        </>
    );
}

//id, name , role, email, phone, status, last login
