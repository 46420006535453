import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInputField from "../../../components/custom_input_filed";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomLoading from "../../../components/custom_loading";
import { toast } from "react-toastify";
//import { UPDATE_VERSION_CONTROL_RESET } from "../../../../../redux/constants/version_control_constants";
//import { getVersionControl, updateVersionControl } from "../../../../../redux/actions/version_control_actions";
import { getVersionControl, resetUpdateVersion, updateVersionControl } from "../../../../../redux/slices/version_control_slice";

export default function VersionControlScreen({ permissions }) {
    //redux
    // const getVersionControlReducer = useSelector((state) => state.getVersioncontrol);
    // const { versionControl, error, loading } = getVersionControlReducer;
    // const updateVersionControlReducer = useSelector((state) => state.updateVersionControl);
    // const { versionControl: updatedVersionControl, error: updateVersionControlError, loading: updateVersionControlLoading, success } = updateVersionControlReducer;

    const getVersionControlReducer = useSelector((state) => state.versionControl);
    const { data: versionControl, loading, updateSuccess, updateError, updateLoading } = getVersionControlReducer;

    // static Data
    // const versionControl = [
    //    {
    //     iosVersion:"0.0.0",
    //     androidVersion:"0.0.0.0",
    //     forceUpdate:true
    // }
    // ]

    //hooks
    const dispatch = useDispatch();

    //useEffects
    useEffect(() => {
        if (versionControl === undefined) {
            dispatch(getVersionControl());
        }
        return () => {
            //dispatch({type:UPDATE_VERSION_CONTROL_RESET});
            dispatch(resetUpdateVersion());
        };
    }, [dispatch, versionControl]);

    useEffect(() => {
        if (versionControl !== undefined) {
            formik.setFieldValue("iosVersion", versionControl[0]?.iosVersion);
            formik.setFieldValue("androidVersion", versionControl[0]?.androidVersion);
            formik.setFieldValue("forceUpdate", versionControl[0]?.forceUpdate);
        }
    }, [versionControl]);
    useEffect(() => {
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Updated successfully!");
            } else {
                toast.error(updateError);
            }
        }
    }, [updateSuccess]);

    const validationSchema = Yup.object().shape({
        androidVersion: Yup.string().required("Android Version is required"),
        iosVersion: Yup.string().required("IOS version is required"),
    });
    const isVersionControlChanged = () => {
        if (formik.values.iosVersion !== versionControl[0].iosVersion) {
            return true;
        }
        if (formik.values.androidVersion !== versionControl[0].androidVersion) {
            return true;
        }
        if (formik.values.forceUpdate !== versionControl[0].forceUpdate) {
            return true;
        }
        return false;
    };

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            androidVersion: "",
            iosVersion: "",
            forceUpdate: false,
        },

        onSubmit: async (data) => {
            data["initialVersionControlId"] = versionControl[0]._id;

            if (isVersionControlChanged()) {
                dispatch(updateVersionControl(data));
            } else {
                toast.warn("Please update some values first");
            }
        },
    });

    return (
        <>
            {loading === true || versionControl === undefined ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <form onSubmit={formik.handleSubmit}>
                        <div className=" pb-5">
                            <div className="grid">
                                <div className="col-12 md:col-7">
                                    <h4>Version Controls Management</h4>
                                </div>
                                <div className="col-12 md:col-5 text-right">{permissions?.edit && <Button loading={updateLoading} label="Update" type="submit" className="p-button-success p-button-rounded p-button-sm custom___width" />} </div>
                            </div>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-12">{/* <h5>Version Controls</h5> */}</div>
                            <CustomInputField iden="androidVersion" formik={formik} label="Android Version" type="text" className="field col-12 md:col-4" />
                            <CustomInputField iden="iosVersion" formik={formik} label="IOS Version" type="text" className="field col-12 md:col-4" />
                            <CustomInputField iden="forceUpdate" formik={formik} label="Force update" type="checkbox" className="field col-12 md:col-4" />
                            {/* <CustomInputField iden="forceUpdate" formik={formik} label="Force Update" type="string" className="field col-12 md:col-4" /> */}
                            {/* <div className="ml-3 mt-4 field-checkbox">
                                <Checkbox inputId="forceUpdate" name='forceUpdate' checked={formik.values.forceUpdate} onChange={formik.handleChange} />
                                <label>Force Update</label>
                            </div> */}
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}
