import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";
import * as consts from "../constants/rides_constants";

const getUserRides = (userId, userType, flag, pageNumber) => async (dispatch, getState) => {
    dispatch({ type: consts.GET_USER_RIDES_REQUEST });

    const url = appUrl.baseUrl + appUrl.getUserRides + `?userId=${userId}&userType=${userType}&flag=${flag}&pageNumber=${pageNumber}`;
    try {
        const { data } = await axiosApi.get(url);
        dispatch({ type: consts.GET_USER_RIDES_SUCCESS, payload: { data: data.data, count: data.count } });
    } catch (error) {
        dispatch({
            type: consts.GET_USER_RIDES_FAILURE,
            payload: error.response && error.response.data.msg ? error.response.data.msg : error.message,
        });
    }
};
const getRideDetails =
    (rideId, flag = "driver") =>
    async (dispatch, getState) => {
        dispatch({ type: consts.GET_RIDE_DETAILS_REQUEST });

        const url = appUrl.baseUrl + appUrl.getRideDetails + `?flag=${flag}&id=${rideId}`;
        try {
            const { data } = await axiosApi.get(url);
            dispatch({ type: consts.GET_RIDE_DETAILS_SUCCESS, payload: data.data });
        } catch (error) {
            dispatch({
                type: consts.GET_RIDE_DETAILS_FAILURE,
                payload: error.response && error.response.data.msg ? error.response.data.msg : error.message,
            });
        }
    };
const getAllRides = (flag, userType, pageNumber, perPageRecords) => async (dispatch, getState) => {
    dispatch({ type: consts.GET_ALL_RIDES_REQUEST });

    const url = appUrl.baseUrl + appUrl.getAllRides + `?flag=${flag}&userType=${userType}&pageNumber=${pageNumber}&perPageRecords=${perPageRecords}`;
    try {
        const { data } = await axiosApi.get(url);
        dispatch({ type: consts.GET_ALL_RIDES_SUCCESS, payload: { data: data.data, count: data.count } });
    } catch (error) {
        dispatch({
            type: consts.GET_ALL_RIDES_FAILURE,
            payload: error.response && error.response.data.msg ? error.response.data.msg : error.message,
        });
    }
};

export { getUserRides, getRideDetails, getAllRides };
