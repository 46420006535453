import * as consts from "../constants/drivers_constants";


function getDriversListReducer(state = {}, action) {
    switch (action.type) {
      case consts.GET_DRIVERS_LIST_REQUEST:
        return { loading: true };
      case consts.GET_DRIVERS_LIST_SUCCESS:
        return { loading: false, drivers: action.payload ,success:true};
      case consts.GET_DRIVERS_LIST_FAILURE:
        return { loading: false, error: action.payload,success:false };
      default: return state;
    }
  }
  
function updateDriverDocumentsStatusReducer(state = {}, action) {
    switch (action.type) {
      case consts.UPDATE_DRIVER_DOCUMENTS_STATUS_REQUEST:
        return { loading: true };
      case consts.UPDATE_DRIVER_DOCUMENTS_STATUS_SUCCESS:
        return { loading: false, drivers: action.payload ,success:true};
      case consts.UPDATE_DRIVER_DOCUMENTS_STATUS_FAILURE:
        return { loading: false, error: action.payload,success:false };
      case consts.UPDATE_DRIVER_DOCUMENTS_STATUS_RESET:
        return {};
      default: return state;
    }
  }


  export {getDriversListReducer,updateDriverDocumentsStatusReducer}