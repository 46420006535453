import React from "react";
import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    video: {
        width: "550px",
        height: "370px",
        [theme.breakpoints.down("xs")]: {
            width: "300px",
            height: "200px",
        },
    },
    gridContainer: {
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    paper: {
        padding: "10px",
        border: "2px solid black",
        margin: "10px",
    },
}));

const VideoPlayer = ({ data }) => {
    const { userToCall, callerName, callAccepted, myVideo, userVideo, callEnded, stream } = data;
    const classes = useStyles();
    return (
        <Grid container className={classes.gridContainer}>
            {stream && (
                <Paper className={classes.paper}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom>
                            {callerName || "Name"}
                        </Typography>
                        <video playsInline muted ref={myVideo} autoPlay className={classes.video} />
                    </Grid>
                </Paper>
            )}
            {callAccepted && !callEnded && (
                <Paper className={classes.paper}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom>
                            {userToCall || "Name"}
                        </Typography>
                        <video playsInline ref={userVideo} autoPlay className={classes.video} />
                    </Grid>
                </Paper>
            )}
        </Grid>
    );
};

export default VideoPlayer;
