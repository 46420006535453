import React from "react";
import { Button } from "@material-ui/core";

const Notifications = ({ data }) => {
    const { answerCall, call, callAccepted } = data;
    return (
        <>
            {call.isReceivingCall && !callAccepted && (
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <h1>{call.name} is calling:</h1>
                    <Button variant="contained" color="primary" onClick={answerCall}>
                        Answer
                    </Button>
                </div>
            )}
        </>
    );
};

export default Notifications;
