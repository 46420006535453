import { Button } from 'primereact/button';
import React from 'react';
import { toast, Toast } from 'react-toastify';
import CustomInputField from '../../../components/custom_input_filed';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { addVehicleMaker, getVehicleMakers, resetAddEditVehicleMakers, updateVehicleMake } from '../../../../../redux/slices/vehicle_makers_slice';
import { useEffect } from 'react';


const VMakerDialoge = ({ onHide, editUser }) => {
  const { addLoading,addSuccess, addError,updateSuccess,updateError,updateLoading } = useSelector((state) => state.vehicleMakers);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    make: Yup.string().required("This field is required."),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      make: "",
    },

    onSubmit: async (data) => {
      ////console.log("datachecking vehicle make", data);

      if (editUser == null) {
        dispatch(addVehicleMaker(data));
      }
      else {
        data['makeId'] = editUser._id;
        // //console.log(data);
        dispatch(updateVehicleMake(data))
      }
    },
  });
  useEffect(() => {
    if (addSuccess !== undefined) {
      if (addSuccess === true) {
        onHide();
        toast.success("Vehicle Make successfully added");
        dispatch(getVehicleMakers());
      } else {
        toast.error(addError);
      }
    }

    if (updateSuccess !== undefined) {
      if (updateSuccess === true) {
        toast.success("Vehicle Make successfully updated");
          onHide();
          dispatch(getVehicleMakers());
      } else {
          toast.error(updateError);
      }
  }

    return () => {
      dispatch(resetAddEditVehicleMakers());
    };
  }, [addSuccess, addError,updateSuccess,updateError]);

  useEffect(() => {
    if (editUser != null) {
      loadInitialValues();
    }
  }, []);

  const loadInitialValues = () => {
    formik.setFieldValue('make', editUser.make);
  }

  return (
    <div>
      {/* <Toast ref={toast} /> */}

      <form onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-12">
            <div className="p-fluid grid">
              <div className="col-12">
                <div className="field">
                  <CustomInputField label="Vehicle Makers" iden={"make"} formik={formik} type="text" />
                </div>
              </div>

              <div className="col-12 md:col-12">
                <div className="button_class-modal">
                  <Button
                    loading={editUser == null ? addLoading : updateLoading}
                    type="submit"
                    label={editUser === null ? "Add" : "Update"}
                    className="p-button-success btn__default_modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default VMakerDialoge;
