import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_filed";
import { TreeSelect } from "primereact/treeselect";
import { getModulesList } from "../../../../../redux/slices/modules_slice";
import { getUserRoles } from "../../../../../redux/slices/admin_user_slice";
import { addRoles, updateRoles, resetAddEditRoles } from "../../../../../redux/slices/roles_slice";

export default function AddEditRole({ editData, onHide, permissions, setRefreshList }) {
    //redux
    const modulesListReducer = useSelector((state) => state.modulesList);
    const { data: modules } = modulesListReducer;

    const roleListReducer = useSelector((state) => state.rolesList);
    const { addSuccess, addError, editSuccess } = roleListReducer;
    //hooks
    const [selectedValue, setSelectedValue] = useState({});
    const [treeOptions, setTreeOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getModulesList());
    }, []);

    useEffect(() => {
        if (modules !== undefined) {
            setOptions();
        }
    }, [modules]);
    const setOptions = async () => {
        let revamped = modules.map((item) => {
            return {
                key: item._id,
                label: item.name,
                children: item.submodule.map((item2) => {
                    return {
                        key: `sub-${item2._id}`,
                        label: item2.name,
                    };
                }),
            };
        });

        setTreeOptions(revamped);
    };
    useEffect(() => {
        // dispatch(getUserRoles());
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Role successfully added");
                onHide();
                dispatch(getUserRoles());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Role successfully updated");
                setRefreshList(true);
                onHide();
                dispatch(getUserRoles());
            } else {
                toast.error(addError);
            }
        }

        return () => {
            dispatch(resetAddEditRoles());
        };
    }, [addSuccess, addError, editSuccess]);

    const validationSchema = Yup.object().shape({
        role: Yup.string().required("Role is required"),
        description: Yup.string().required("Description is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            role: "",
            description: "",
        },

        onSubmit: async (data) => {
            const keysArray = [];
            Object.keys(selectedValue).map(function (key) {
                if (key.startsWith("sub-")) {
                    const modifiedKey = key.slice(4);
                    keysArray.push(modifiedKey);
                } else {
                    keysArray.push(key);
                }
            });
            let body = {
                permissions: keysArray,
                perm_value: JSON.stringify(selectedValue),
            };
            let bothData = {
                ...body,
                ...data,
            };

            if (editData === null) {
                dispatch(addRoles(bothData));
            } else {
                bothData["roleId"] = editData._id;
                dispatch(updateRoles(bothData));
            }
        },
    });
    useEffect(() => {
        if (editData !== null) {
            loadValues();
        }
        dispatch(getUserRoles());
        if (editData && editData?.perm_value) {
            setSelectedValue(JSON.parse(editData.perm_value));
        }
    }, []);

    const loadValues = () => {
        formik.setFieldValue("role", editData.role);
        formik.setFieldValue("description", editData.description);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className=" p-fluid grid">
                        <div className="col-12">
                            <TreeSelect
                                className="field"
                                value={selectedValue}
                                options={treeOptions}
                                onChange={(e) => {
                                    console.log(e.value);
                                    setSelectedValue(e.value);
                                }}
                                display="chip"
                                selectionMode="checkbox"
                                placeholder="Select Permission"
                            ></TreeSelect>
                        </div>

                        <div className="col-12">
                            <CustomInputField iden="role" label="Role" formik={formik} disabled={editData ? true : false} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="description" label="Description" formik={formik} />
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                {permissions?.add && editData == null && <Button type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}
                                {permissions?.edit && editData && <Button type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
