import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import AddEditTutorial from "../pages/add-edit-tutorial-dailoge";
import { deleteTutorial } from "../../../../../redux/slices/tutorials_slice";

export default function TutorialItem({ tutorial, video, title }) {
    const toast = useRef(null);
    const dispatch = useDispatch();

    //hooks
    const [expanded, setExpanded] = useState(false);
    const [editTutorial, setEditTutorial] = useState(null);
    const [showAddEditTutorialDialog, setShowAddEditTutorialDialog] = useState(false);

    const accept = () => {
        dispatch(deleteTutorial(tutorial._id));
    };

    const reject = () => {};
    const deleteTutorialsCategory = () => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    };

    const dialogFuncMap = {
        showAddEditTutorialDialog: setShowAddEditTutorialDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const extractVideoId = (url) => {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
            return match[2];
        }
        return null;
    };
    return (
        <>
            <div>
                <Dialog header="Update Tutorial" visible={showAddEditTutorialDialog} onHide={() => onHide("showAddEditTutorialDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                    <AddEditTutorial editData={editTutorial} tutorial={tutorial} onHide={() => onHide("showAddEditTutorialDialog")} />
                </Dialog>
                <div
                    className="card"
                    style={{ background: "lightGrey" }}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    <ConfirmDialog />
                    <Toast ref={toast} />
                    <div className="">
                        <div className="grid">
                            <h4>{title}</h4>
                            <div className="col-10"></div>
                            <iframe width="560" height="315" src={`https://www.youtube.com/embed/${extractVideoId(video)}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <div className="col-2">
                                <Button
                                    icon="pi pi-pencil"
                                    onClick={() => {
                                        onClick("showAddEditTutorialDialog");
                                        setEditTutorial(tutorial);
                                    }}
                                    className="p-button-rounded p-button-text p-0"
                                    aria-label="Submit"
                                    style={{ color: "blue", cursor: "pointer" }}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-text"
                                    aria-label="Submit"
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={deleteTutorialsCategory}
                                    //onClick={()=>deleteFaqOnClick()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
