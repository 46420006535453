const socketsPoint = {
    getAllTicketsEmitter: "allTickets",
    newSupportMessageEmitter: "newSupportMessage",
    ticketChatEmitter: "ticketChat",
    getPendingVehicleCountEmitter: "pendingVehicleCount",
    getUnverifiedDriversCountEmitter: "unverifiedDriversCount",
    newSupportMessageListener: "newSupportMessage",
    newSupportAttachmentListener: "newSupportAttachments",
    addAttachement: "uploadSupportAttachments",
    updateStatusSupportTicket: "updateStatusSupportTicket",
    newVehicleListener: "newVehicle",
    sideBarUpdate: "moduleUpdate",
    getSideBarEmitter: "getModulesByRole",
    permissionsUpdatedListener: "permissionUpdated",
};

export default socketsPoint;
