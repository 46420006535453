import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import CustomInputField from "../../../components/custom_input_filed";
// import { useDispatch, useSelector } from 'react-redux';
// import { addAdminUser, editAdminUser, getAdminUsers, getUserRoles } from '../../../../../redux/actions/admin_user_actions';
// import { RESET_ADD_ADMIN_USER, RESET_EDIT_ADMIN_USER } from '../../../../../redux/constants/admin_user_constants';
// import CustomLoading from '../../../components/custom_loading';

export default function AddEditNotificationDialog({ onHide, editUser, permissions }) {
    // const getRoles = useSelector((state) => state.getUserRoles);
    // const addAdminUserReducer = useSelector((state) => state.addAdminUser);
    // const editAdminReducer = useSelector((state) => state.editAdminUser);
    // const { roles, loading } = getRoles;
    // const { success: addSuccess, loading: addLoading, error: addUserError } = addAdminUserReducer;
    // const { success: editSuccess, loading: editLoading, error: editUserError } = editAdminReducer;
    // const dispatch = useDispatch();

    // const toast = useRef(null);

    // const statusList=[
    //         {
    //             'code':true,
    //             'name':'active',
    //         },
    //         {
    //             'code':false,
    //             'name':'block',
    //         },
    // ];

    // const loadEditData = () => {
    //     //console.log(editUser);
    //     // formik.setFieldValue("status", editUser['status']);
    //     formik.setFieldValue("userName", editUser['userName']);
    //     formik.setFieldValue("email", editUser['email']);
    //     formik.setFieldValue("mobile", editUser['mobile']);
    //     formik.setFieldValue("status", editUser['status']);
    //     formik.setFieldValue("role", editUser['role']['_id']);

    // }

    // useEffect(() => {
    //     //console.log("Getting roles as well");
    //     if (roles === undefined || roles.length === 0) {
    //         //console.log("Testing");
    //         dispatch(getUserRoles());
    //     }
    //     if (editUser != null) {
    //         loadEditData();
    //     }

    // }, []);

    // useEffect(() => {
    //     //console.log(addSuccess);
    //     //console.log(addUserError);

    //     if (addSuccess != undefined) {
    //         if (addSuccess === true) {
    //             toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'User added successfully', life: 3000 });
    //             formik.resetForm();
    //             onHide();
    //             dispatch(getAdminUsers());

    //         } else {
    //             toast.current.show({ severity: 'error', summary: 'Error Message', detail: addUserError, life: 3000 });

    //         }
    //     }

    //     if (editSuccess != undefined) {
    //         if (editSuccess === true) {
    //             toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'User updated successfully', life: 3000 });
    //             formik.resetForm();
    //             onHide();
    //             dispatch(getAdminUsers());

    //         } else {
    //             toast.current.show({ severity: 'error', summary: 'Error Message', detail: editUserError, life: 3000 });

    //         }
    //     }

    //     return ()=>{
    //        dispatch({type:RESET_ADD_ADMIN_USER});
    //        dispatch({type:RESET_EDIT_ADMIN_USER});
    //     }

    // }, [addSuccess, addUserError,editSuccess,editUserError]);

    // const validationSchema = Yup.object().shape({
    //     userName: Yup.string().required("This field is required."),
    //     password: editUser == null ? Yup.string().required("This field is required.").min(8, "Minimum length should be 8") : Yup.string().min(8, "Minimum length should be 8"),
    //     email: Yup.string().email("Invalid email address format").required("This field is required."),
    //     mobile: Yup.string()
    //         .required("This field is required.")
    //         .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
    //         .max(100, "Maximum length 100 allowed")
    //         .nullable(),
    //     role: Yup.string().required("Role is required"),
    // });

    const formik = useFormik({
        // validationSchema: validationSchema,
        // initialValues: {
        //     userName: "",
        //     password: "",
        //     email: "",
        //     mobile: "",
        //     role: "",
        //     status: "",
        // },
        //     onSubmit: async (data) => {
        //         //console.log(data);
        //         if(editUser==null){
        //             dispatch(addAdminUser(data));
        //         }else{
        //             data['userId']=editUser._id;
        //             dispatch(editAdminUser(data));
        //         }
        //         // setLoading(true);
        //         // setloadingIcon("pi pi-spin pi-spinner");
        //         // if (editable === true) {
        //         //     data["userId"] = UsersRowData;
        //         //     const res = await dispatch(handlePatchRequest(data, "api/v1/user/", true, true));
        //         //     if (res?.status === 200) {
        //         //         await getUserData();
        //         //         formik.resetForm();
        //         //         onHide();
        //         //     }
        //         // } else {
        //         //     const res = await dispatch(handlePostRequest(data, "api/v1/user/", true, true));
        //         //     if (res?.status === 200) {
        //         //         await getUserData();
        //         //         formik.resetForm();
        //         //         onHide();
        //         //     }
        //         // }
        //         // setLoading(false);
        //         // setloadingIcon("pi pi-save");
        //     },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onBasicUpload = () => {
        //toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
    };
    return (
        <>
            {/* <Toast ref={toast} /> */}
            <form onSubmit={formik.handleSubmit}>
                {/*{
                loading === true ? <CustomLoading /> : */}
                <div className="col-12 md:col-6\8">
                    <div className=" p-fluid">
                        <div className="field">
                            {/* <CustomInputField iden={"userName"} formik={formik} type="text" /> */}
                            <label htmlFor="name1">Title</label>
                            <InputText
                                id="userName"
                                name="userName"
                                // value={formik.values.userName}
                                onChange={formik.handleChange}
                                type="text"
                                className={classNames({ "p-invalid": isFormFieldValid("userName") })}
                            />
                            {getFormErrorMessage("userName")}
                        </div>
                        <div className="field">
                            {/* <CustomInputField iden={"message"} formik={formik} type="textarea" rows={3} /> */}
                            <label htmlFor="name1">Message</label>
                            <InputText
                                id="message"
                                name="message"
                                // value={formik.values.message}
                                onChange={formik.handleChange}
                                type="text"
                                className={classNames({ "p-invalid": isFormFieldValid("message") })}
                            />
                            {getFormErrorMessage("message")}
                        </div>
                        <div className="field">
                            {/* <label htmlFor="age1">Mobile</label>
                            <InputText id="age1" type="tel" name="mobile" value={formik.values.mobile} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("mobile") })} />
                            {getFormErrorMessage("mobile")} */}
                            <FileUpload
                                mode="basic"
                                name="demo[]"
                                url="https://primefaces.org/primereact/showcase/upload.php"
                                accept="image/*"
                                maxFileSize={1000000}
                                onUpload={onBasicUpload}
                                // className="p-button-success p-button-rounded p-button-sm custom___width"
                            />
                        </div>
                        {/* {editUser != null && (
                            <div className="field">
                                <label htmlFor="age1">Status</label>
                                <Dropdown optionLabel="name" optionValue="code" name="status" value={formik.values.status} 
                                //options={statusList} 
                                onChange={formik.handleChange} placeholder="Select a status" className={classNames({ "p-invalid": isFormFieldValid("role") })} />
                            </div>
                        )} */}
                        {/* editUser == null ? addLoading : editLoading */}
                        <div className="button_class-modal">
                            <Button loading={editUser} type="submit" label="Send" className="p-button-success p-button-rounded btn__default_modal" />
                        </div>
                    </div>
                </div>
                {/* } */}
            </form>
        </>
    );
}
