import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { addRoleModulePermissions, resetPermissionState, roleModulePermissions } from "../../../../../redux/slices/permission_slice";

export default function AddEditRolePermission({ editData, onHide, roleId, permissions }) {
    //redux
    const permissionListReducer = useSelector((state) => state.permissionList);
    const { modulesPermissions, updatedSuccess } = permissionListReducer;
    //hooks

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(roleModulePermissions({ roleId, subModuleId: editData.subModuleId }));
    }, []);

    const formik = useFormik({
        initialValues: {
            roleId,
            subModuleId: editData.subModuleId,
            add: modulesPermissions ? modulesPermissions.permissions.add : false,
            view: modulesPermissions ? modulesPermissions.permissions.view : false,
            edit: modulesPermissions ? modulesPermissions.permissions.edit : false,
            delete: modulesPermissions ? modulesPermissions.permissions.delete : false,
        },

        onSubmit: async (data) => {
            const body = {
                roleId: data.roleId,
                subModuleId: data.subModuleId,
                permissions: {
                    add: data.add,
                    view: data.view,
                    edit: data.edit,
                    delete: data.delete,
                },
            };
            dispatch(addRoleModulePermissions(body));
        },
    });

    useEffect(() => {
        if (updatedSuccess) {
            onHide();
            dispatch(resetPermissionState());
        }
    }, [updatedSuccess]);
    const loadEditData = (data) => {
        formik.setFieldValue("add", data.permissions.add);
        formik.setFieldValue("edit", data.permissions.edit);
        formik.setFieldValue("delete", data.permissions.delete);
        formik.setFieldValue("view", data.permissions.view);
    };

    useEffect(() => {
        if (modulesPermissions) {
            loadEditData(modulesPermissions);
        }
        return () => {
            loadEditData({ permissions: { add: false, edit: false, delete: false, view: false } });
        };
    }, [modulesPermissions]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12">
                    <div className=" p-fluid grid">
                        <div className="col-6">
                            <div className="field-checkbox">
                                <Checkbox inputId="view" name="view" checked={formik.values.view} onChange={formik.handleChange} />
                                <label htmlFor="view">View</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="field-checkbox">
                                <Checkbox inputId="add" name="add" checked={formik.values.add} onChange={formik.handleChange} />
                                <label htmlFor="add">Add</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="field-checkbox">
                                <Checkbox inputId="edit" name="edit" checked={formik.values.edit} onChange={formik.handleChange} />
                                <label htmlFor="edit">Edit</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="field-checkbox">
                                <Checkbox inputId="delete" name="delete" checked={formik.values.delete} onChange={formik.handleChange} />
                                <label htmlFor="delete">Delete</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="button_class-modal">
                        {permissions?.add && editData == null && (
                            <Button
                                // loading={editData == null ? addLoading : editLoading}
                                type="submit"
                                label={editData === null ? "Add" : "Update"}
                                className="p-button-success btn__default_modal p-button-rounded"
                            />
                        )}
                        {permissions?.edit && editData && (
                            <Button
                                // loading={editData == null ? addLoading : editLoading}
                                type="submit"
                                label={editData === null ? "Add" : "Update"}
                                className="p-button-success btn__default_modal p-button-rounded"
                            />
                        )}
                    </div>
                </div>
            </form>
        </>
    );
}
