import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAds, getAdsList, resetAddEditDeleteAds } from "../../../../../redux/slices/ads_slice";
import CustomPageHeader from "../../../components/custom_page_header";
import appUrl from "../../../constants/appUrl";
import { Image } from "primereact/image";
import { confirmDialog } from "primereact/confirmdialog";
import AddEditAdDialog from "../componenets/add_edit_ad_dialog";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Paginator } from "primereact/paginator";

export default function AdsScreen({ permissions }) {
    const dispatch = useDispatch();

    const [editData, setEditData] = useState(null);
    const [first, setFirst] = useState(0);
    const [pageNumber, setPageNumber] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };
    let deleteId;

    const { data: list, loading, deleteSuccess, deleteError, count = 0 } = useSelector((state) => state.adsList);
    const confirmDeleteAd = () => {
        confirmDialog({
            message: "Do you want to delete this ad?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-success p-button-rounded",
            accept,
        });
    };
    const accept = () => {
        let data = {
            adId: deleteId,
        };
        dispatch(deleteAds(data));
    };

    useEffect(() => {
        if (list === undefined) {
            dispatch(getAdsList());
        }
    }, []);

    useEffect(() => {
        if (deleteSuccess !== undefined) {
            if (deleteSuccess === true) {
                toast.success("Ad deleted");
                dispatch(getAdsList());
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetAddEditDeleteAds());
        };
    }, [deleteSuccess, deleteError]);

    const [showAddDialog, setAddDialog] = useState(false);

    const dialogFuncMap = {
        showAddDialog: setAddDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.edit && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditData(rowData);
                            onClick("showAddDialog");
                        }}
                        className="edit mr-2 p-button-rounded p-button-success"
                    />
                )}
                {permissions?.delete && (
                    <Button
                        tooltip="Delete"
                        icon="pi pi-trash"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            deleteId = rowData._id;
                            confirmDeleteAd();
                        }}
                        className="edit mr-2 p-button-danger p-button-rounded"
                    />
                )}
            </div>
        );
    };
    useEffect(() => {
        if (globalFilterValue) {
            setPageNumber(0);
            setFirst(0);
        }
        dispatch(getAdsList({ pageNumber, text: globalFilterValue }));
    }, [globalFilterValue]);
    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        dispatch(getAdsList({ pageNumber: event.first / event.rows, text: globalFilterValue }));
    };
    return (
        <>
            <div className="card">
                <Dialog header={editData == null ? "Add Advertisement" : "Update Advertisement"} visible={showAddDialog} onHide={() => onHide("showAddDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                    <AddEditAdDialog permissions={permissions} editCode={editData} onHide={() => onHide("showAddDialog")} />
                </Dialog>
                <div className="grid">
                    <div className="col-12 md:col-5">
                        <CustomPageHeader title={"Advertisement"} />
                    </div>
                    <div className="col-12 md:col-7">
                        <div className="text-right">
                            <span className="p-input-icon-right mr-3">
                                <InputText className="mbl_mrb" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>

                            <Button
                                onClick={() => {
                                    setEditData(null);
                                    onClick("showAddDialog");
                                }}
                                label="Add"
                                className=" p-button-success p-button-rounded p-button-sm custom___width"
                            />
                        </div>
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" value={list} emptyMessage="No record found." filters={filters} globalFilterFields={["title", "buttonText", "redirectUrl"]}>
                    <Column field="title" header="Title" />
                    <Column
                        header="Image"
                        body={(data) => {
                            return (
                                <div>
                                    <Image width="50px" preview template="Preview" src={`${appUrl.fileBaseUrl}${data?.imageUrl}`} />
                                </div>
                            );
                        }}
                    />
                    <Column field="buttonText" header="Button Text" />
                    <Column field="redirectUrl" header="Redirect Url" />
                    <Column body={actionTemplate} header="Action" />
                </DataTable>
                <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
            </div>
        </>
    );
}
