import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const driversReducer = createSlice({
    name: "drivers",
    initialState: {},
    reducers: {
        resetUpdateDrivers(state, action) {
            return {
                ...state,
                updateSuccess: undefined,
                //editSuccess: undefined
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDriversList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getDriversList.fulfilled, (state, action) => {
                return { ...state, loading: false, drivers: action.payload.data, count: action.payload.count };
            })
            .addCase(getDriversList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
        builder
            .addCase(updateDriverDocumentStatus.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateDriverDocumentStatus.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateDriverDocumentStatus.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false,
                };
            });
        // builder
        //     .addCase(editCorporateCode.pending, (state, action) => {
        //         return { ...state, editLoading: true }
        //     })
        //     .addCase(editCorporateCode.fulfilled, (state, action) => {

        //         return { ...state, editLoading: false, editData: action.payload,editSuccess:true }
        //     })
        //     .addCase(editCorporateCode.rejected, (state, action) => {

        //         return {
        //             ...state, editLoading: false,
        //             error: action.payload,
        //             editSuccess:false
        //         }
        //     });
    },
});
export default driversReducer.reducer;
export const { resetUpdateDrivers } = driversReducer.actions;

// Thunks
export const getDriversList = createAsyncThunk("drivers/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getDriversList + `?userType=1&pageNumber=${body.pageNumber}&text=${body.text}&perPageRecords=${body.perPageRecords}&gender=${body.gender}&status=${body.status}&loginDevice=${body.loginDevice}`);
        //console.log("Owner Data",data)
        return fulfillWithValue({ data: data.data, count: data.count });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const updateDriverDocumentStatus = createAsyncThunk("drivers/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.updateDriverDocumentStatus, body);
        // //console.log("updated data",data);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
// export const editCorporateCode = createAsyncThunk('drivers/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
//     try {
//         const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.editCorporateCode, body);
//         return fulfillWithValue(data.data);
//     } catch (error) {

//         throw rejectWithValue(error.response && error.response.data.msg
//             ? error.response.data.msg
//             : error.message)

//     }

// });
