import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReceiptDetails } from "../../../redux/slices/get_receipt_details_slice";
import CustomLoading from "./custom_loading";
import { Divider } from "primereact/divider";
import { formateDateTime } from "../services/date_service";

export default function ReceiptDialog({ receiptId, permissions }) {
    const getReceiptDetailsReducer = useSelector((state) => state.receiptDetails);
    const { data: reciept, loading, error } = getReceiptDetailsReducer;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getReceiptDetails(receiptId));
    }, []);

    const receiptItemComponent = (desc, info, bold = false) => {
        return (
            <div className={`flex flex-row justify-content-between ${bold ? "font-bold" : ""}`}>
                <div>{desc}</div>
                <div>{info}</div>
            </div>
        );
    };

    return (
        <>
            {(loading === true || reciept === undefined) && error === undefined ? (
                <CustomLoading />
            ) : loading === false && error !== undefined ? (
                <div>{error}</div>
            ) : (
                <div>
                    <Divider type="dashed" />
                    <Divider type="dashed" />
                    <div className="text-center font-bold">
                        <h2>RECEIPT</h2>
                    </div>
                    <Divider type="dashed" />
                    <Divider type="dashed" />

                    <div className="px-5">
                        {receiptItemComponent("Trace No", reciept.paymentDetails.traceNo)}
                        {receiptItemComponent("Transaction Id", reciept.paymentDetails.transactionId)}
                        {receiptItemComponent("Sender Number", reciept.paymentDetails.customerMobile)}
                        {receiptItemComponent("Receiver Number", reciept.paymentDetails.receiverMobileNumber)}
                        {receiptItemComponent("Date", formateDateTime(reciept.paymentDetails.createdAt))}
                        <Divider type="dashed" />
                        {receiptItemComponent("Payment Method", reciept.method)}

                        <Divider type="dashed" />
                        {receiptItemComponent("Amount", `Rs. ${reciept?.paymentDetails?.totalAmount}`, true)}

                        <Divider type="dashed" />

                        <div className="mb-5"></div>
                    </div>
                </div>
            )}
        </>
    );
}
