import { Button } from "primereact/button";
import React from "react";
import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_filed";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addVehicleType, getVehicleType, resetAddEditVehicleType, updateVehicleType } from "../../../../../redux/slices/vehicle_type_slice";
import { useEffect } from "react";

const VTypeDialoge = ({ onHide, editUser, permissions }) => {
    const { addLoading, addSuccess, addError, updateLoading, updateSuccess, updateError } = useSelector((state) => state.vehicleType);
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        type: Yup.string().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            type: "",
        },

        onSubmit: async (data) => {
            if (editUser == null) {
                dispatch(addVehicleType(data));
            } else {
                data["typeId"] = editUser._id;
                // //console.log(data);
                dispatch(updateVehicleType(data));
            }
        },
    });
    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Vehicle Type successfully added");
                onHide();
                dispatch(getVehicleType());
            } else {
                toast.error(addError);
            }
        }
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Vehicle Type successfully updated");
                onHide();
                dispatch(getVehicleType());
            } else {
                toast.error(updateError);
            }
        }
        return () => {
            dispatch(resetAddEditVehicleType());
        };
    }, [addSuccess, addError, updateSuccess, updateError]);

    useEffect(() => {
        if (editUser != null) {
            loadInitialValues();
        }
    }, []);

    const loadInitialValues = () => {
        formik.setFieldValue("type", editUser.type);
    };
    return (
        <div>
            {/* <Toast ref={toast} /> */}

            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <div className="p-fluid grid">
                            <div className="col-12">
                                <div className="field">
                                    <CustomInputField label="Vehicle Type" iden={"type"} formik={formik} type="text" />
                                </div>
                            </div>

                            <div className="col-12 md:col-12">
                                <div className="button_class-modal">{permissions?.add && editUser == null && <Button loading={editUser == null ? addLoading : updateLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal" />} </div>
                                <div className="button_class-modal">{permissions?.edit && editUser && <Button loading={editUser == null ? addLoading : updateLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal" />} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default VTypeDialoge;
