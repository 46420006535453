import React from 'react';

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <img src='app/logo.png' alt="Logo" height="30px" className="mr-2" />

            <span className="font-medium ml-2"><sup>©</sup>{new Date().getUTCFullYear()}</span>
        </div>
    );
}
