import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const faqSlice = createSlice({
    name: 'faqSlice',
    initialState: {},
    reducers: {
        resetAddEditFaq(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFaqsList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getFaqsList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
                
            })
            .addCase(getFaqsList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
        builder
            .addCase(addFaq.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addFaq.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addFaq.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    error: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(editFaq.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editFaq.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editFaq.rejected, (state, action) => {

                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(deleteFaqs.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteFaqs.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(deleteFaqs.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default faqSlice.reducer;
export const { resetAddEditFaq } = faqSlice.actions;


// Thunks
export const getFaqsList = createAsyncThunk('faqs/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getFaqsList);
        ////console.log("faq",data)
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addFaq = createAsyncThunk('faqs/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addFaqs, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editFaq = createAsyncThunk('faqs/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editFaqs, body);

        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});

export const deleteFaqs = createAsyncThunk('faq/delete', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deleteFaqs+`?FAQId=${id}`);
        
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});


