import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const tutorialsCategorySlice = createSlice({
    name: 'tutorialsCategorySlice',
    initialState: {},
    reducers: {
        resetAddEditTutorialsCategory(state, action) {
            return { ...state, addSuccess: undefined, updateSuccess: undefined, deleteSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTutorialsCategoryList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getTutorialsCategoryList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getTutorialsCategoryList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addTutorialsCategory.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addTutorialsCategory.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addTutorialsCategory.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(updateTutorialsCategory.pending, (state, action) => {
                return { ...state, updateLoading: true }
            })
            .addCase(updateTutorialsCategory.fulfilled, (state, action) => {

                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true }
            })
            .addCase(updateTutorialsCategory.rejected, (state, action) => {

                return {
                    ...state, updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false
                }
            });
        builder
            .addCase(deleteTutorialsCategory.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteTutorialsCategory.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(deleteTutorialsCategory.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    deleteError: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default tutorialsCategorySlice.reducer;
export const { resetAddEditTutorialsCategory } = tutorialsCategorySlice.actions;


// Thunks
export const getTutorialsCategoryList = createAsyncThunk('tutorialsCategory/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getTutorialsCategoryList);
       // //console.log("data",data)
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addTutorialsCategory = createAsyncThunk('tutorialsCategory/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addTutorialsCategory, body);
        return fulfillWithValue(data.data);
        
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const updateTutorialsCategory = createAsyncThunk('tutorialsCategory/update', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.updateTutorialsCategory, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
export const deleteTutorialsCategory = createAsyncThunk('tutorialsCategory/delete', async (body, { rejectWithValue, fulfillWithValue }) => {
    // //console.log(body)
    try {
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deleteTutorialsCategory+`?categoryId=${body._id}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
