import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const AccountsSlice = createSlice({
    name: "accounts",
    initialState: {},
    reducers: {
        resetAdminUser(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined };
        },
        logoutUser(state, action) {
            localStorage.removeItem("user");
            return {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountsList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getAccountsList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getAccountsList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
    },
});

export default AccountsSlice.reducer;
export const { resetAdminUser, logoutUser } = AccountsSlice.actions;

// Thunks

export const getAccountsList = createAsyncThunk("getAccountsList/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAccountsList + `?status=${body.status}&text=${body.text}`);
        //    //console.log('accounts module data here' ,data)
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
