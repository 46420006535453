import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const vehicleTypeSlice = createSlice({
    name: 'vehicleType',
    initialState: { },
    reducers: {
        resetAddEditVehicleType(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteTypeSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVehicleType.pending, (state, action) => {
                return {loading:true}
            })
            .addCase(getVehicleType.fulfilled, (state, action) => {
            //    //console.log(action.payload)
                return {loading:false,data:action.payload}
            })
            .addCase(getVehicleType.rejected, (state, action) => {
                return {
                    loading:false,
                    error:action.error.response && action.error.response.data.msg
                    ? action.error.response.data.msg
                    : action.error.message
                }
            });
            builder
            .addCase(addVehicleType.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addVehicleType.fulfilled, (state, action) => {
                // //console.log(action.payload);

                return { ...state, addLoading: false, addData: action.payload ,addSuccess:true}
            })
            .addCase(addVehicleType.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess:false
                }
            });
            builder
            .addCase(updateVehicleType.pending, (state, action) => {
                return { ...state, updateLoading: true }
            })
            .addCase(updateVehicleType.fulfilled, (state, action) => {

                return { ...state, updateLoading: false, updateData: action.payload ,updateSuccess:true}
            })
            .addCase(updateVehicleType.rejected, (state, action) => {

                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess:false
                }
            });
            builder
            .addCase(deleteVehicleType.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteVehicleType.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload,deleteTypeSuccess:true }
            })
            .addCase(deleteVehicleType.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteTypeSuccess:false
                }
            });
    },
});
export default vehicleTypeSlice.reducer;
export const { resetAddEditVehicleType } = vehicleTypeSlice.actions;
// Thunks
export const getVehicleType = createAsyncThunk('vehicleType/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getVehicleType);
        // //console.log("vehicle",data)
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});


export const addVehicleType = createAsyncThunk('addVehicleType/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addVehicleType, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});


export const updateVehicleType = createAsyncThunk('vehicleType/update', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.updateVehicleType, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

export const deleteVehicleType = createAsyncThunk('ads/delete', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
   
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deleteVehicleType+`?typeId=${body.typeId}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});