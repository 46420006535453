import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import CustomInputField from "../../../components/custom_input_filed";
import appUrl from "../../../constants/appUrl";
import { resetUpdateVehicleDocumentStatus, updateVehicleDocumentStatus, getVehicleDocumentsList } from "../../../../../redux/slices/vehicle_verification_slice";
import { Image } from "primereact/image";

export default function EditVehicleDialog({ onHide, editData, status, pageNumber = 0 }) {
    //redux
    const VechileDocumentsReducer = useSelector((state) => state.vehicleDocuments);
    const { updateLoading, updateSuccess, updateError } = VechileDocumentsReducer;

    //hooks
    const dispatch = useDispatch();
    const toast = useRef(null);
    // console.log("personal", editData.documents.map((item) => item.status))
    const list = [
        { status: "Verify", value: "verified" },
        { status: "Reject", value: "rejected" },
        // { status: "Pending", value: "pending" },
    ];

    useEffect(() => {
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                //toast.success("Vechicle modified");

                onHide();
                dispatch(getVehicleDocumentsList({ status, pageNumber }));
            } else {
                toast.error(updateError);
            }
        }

        return () => {
            dispatch(resetUpdateVehicleDocumentStatus());
        };
    }, [updateSuccess, updateError]);
    const validationSchema = Yup.object().shape({
        status: Yup.string().required("Status is required"),
        comment: Yup.string().required("Comment is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: "",
            comment: "",
        },

        onSubmit: async (data) => {
            // if (data.status === false && data.comment.length === 0) {
            //     toast.current.show({ severity: 'warn', summary: 'Error Message', detail: 'Comment is required', life: 3000 });
            //     return;
            // }
            // data['userId'] = editUser._id;
            // if (editUser != null) {
            //     dispatch(updateUserStatus(data));
            // }

            data["vehicleId"] = editData._id;
            data["type"] = "registrationCard";
            if (editData !== null) {
                dispatch(updateVehicleDocumentStatus(data));
            }
        },
    });
    const loadEditData = () => {
        formik.setFieldValue("status", editData.documents[0].status);
        // console.log("status", editData.documents[0].status)
    }

    useEffect(() => {
        loadEditData();
    }, [])

    return (
        <>
            <Toast ref={toast} />

            <form onSubmit={formik.handleSubmit}>
                {
                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">
                            {/* <div className="field-checkbox">
                                <Checkbox inputId="status" name="status" checked={formik.values.status} onChange={formik.handleChange} />
                                <label htmlFor="status">Active status</label>
                            </div> */}
                            {/* {!formik.values.status && ( */}
                            <div className="flex justify-content-center">
                                {editData?.documents[0]?.vehicleImage && <Image width="100px" preview template="Preview" src={`${appUrl.fileBaseUrl}${editData?.documents[0]?.vehicleImage}`} alt="Vehicle Image" className="mr-2" />}
                                {editData?.documents[0]?.frontImage && <Image width="100px" preview template="Preview" src={`${appUrl.fileBaseUrl}${editData?.documents[0].frontImage}`} alt="Front Image" className="mr-2" />}
                                {editData?.documents[0]?.backImage && <Image width="100px" preview template="Preview" src={`${appUrl.fileBaseUrl}${editData?.documents[0].backImage}`} alt="Back Image" />}
                            </div>
                            <div className="field">
                                <CustomInputField iden={"status"} formik={formik} options={list} type="dropdown" optionLabel="status" optionValue="value" placeholder="Select a status" />
                            </div>
                            <div></div>
                            <div className="field">
                                <CustomInputField iden={"comment"} formik={formik} type="textarea" />
                            </div>
                            {/* )} */}
                            <Button type="submit" label="Submit" className="p-button-success" />
                        </div>
                    </div>
                }
            </form>
        </>
    );
}
