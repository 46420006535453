import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const fareManagementReducer = createSlice({
    name: 'fareManagementReducer',
    initialState: {},
    reducers: {
        resetUpdateFare(state, action) {
            return { ...state, updateSuccess: undefined, editSuccess: undefined,deleteSuccess:undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFares.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getFares.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getFares.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
            builder
            .addCase(updateFares.pending, (state, action) => {
                return { ...state, updateLoading: true }
            })
            .addCase(updateFares.fulfilled, (state, action) => {

                return { ...state, updateLoading: false, updateData: action.payload ,updateSuccess:true}
            })
            .addCase(updateFares.rejected, (state, action) => {

                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess:false
                }
            });
    },
});

export default fareManagementReducer.reducer;
export const { resetUpdateFare } = fareManagementReducer.actions;



// Thunks
export const getFares = createAsyncThunk('fare/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getFares);
        
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});


export const updateFares = createAsyncThunk('fare/update', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.updateFares, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

