import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const vehicleVerificationSlice = createSlice({
    name: "vehicleVerificationSlice",
    initialState: {},
    reducers: {
        resetUpdateVehicleDocumentStatus(state, action) {
            return { ...state, updateSuccess: undefined };
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getVehicleDocumentsList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getVehicleDocumentsList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload.data, count: action.payload.count };
            })
            .addCase(getVehicleDocumentsList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });

        builder
            .addCase(updateVehicleDocumentStatus.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateVehicleDocumentStatus.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateVehicleDocumentStatus.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false,
                };
            });

        // builder
        // .addCase(getUserVehiclesById.pending, (state, action) => {
        //     return { ...state, usersLoading: true };
        // })
        // .addCase(getUserVehiclesById.fulfilled, (state, action) => {
        //     return { ...state, usersLoading: false, user: action.payload };
        // })
        // .addCase(getUserVehiclesById.rejected, (state, action) => {
        //     return {
        //         ...state,
        //         usersLoading: false,
        //         usersError: action.payload,
        //     };
        // });
    },
});

export default vehicleVerificationSlice.reducer;
export const { resetUpdateVehicleDocumentStatus } = vehicleVerificationSlice.actions;

// Thunks
export const getVehicleDocumentsList = createAsyncThunk("vehicleDocumetns/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    //console.log(body)
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getVehicleDocumentsList + `?status=${body.status}&pageNumber=${body.pageNumber}&perPageRecords=${body.perPageRecords}`);
        //console.log("vehicle data",data,data.count)
        return fulfillWithValue({ data: data.data, count: data.count });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const updateVehicleDocumentStatus = createAsyncThunk("vehicleDocumetns/update", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.updateVehicleDocumentStatus, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

// export const getUserVehiclesById = createAsyncThunk("getUsersById/fetch", async ({ userId, isDriver }, { rejectWithValue, fulfillWithValue }) => {
//     try {
//         // //console.log("userId",userId)
//         const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.getUserVehiclesById);
//         //console.log("user data vehicles",data)
//         return fulfillWithValue(data.user);

//     } catch (error) {
//         throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
//     }
// });
