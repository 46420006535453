import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const permissionSlice = createSlice({
    name: "permissionList",
    initialState: {},
    reducers: {
        resetPermissionState(state, action) {
            return { ...state, modulesPermissions: undefined, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined, getSuccess: undefined, updatedSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getModulePermission.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getModulePermission.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload, getSuccess: true };
            })
            .addCase(getModulePermission.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                    getSuccess: false,
                };
            });
        builder
            .addCase(roleModulePermissions.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(roleModulePermissions.fulfilled, (state, action) => {
                return { ...state, modulesPermissions: action.payload };
            })
            .addCase(roleModulePermissions.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(addRoleModulePermissions.pending, (state, action) => {
                return { ...state, addLoading: true, updatedSuccess: false };
            })
            .addCase(addRoleModulePermissions.fulfilled, (state, action) => {
                return { ...state, data: action.payload, updatedSuccess: true };
            })
            .addCase(addRoleModulePermissions.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
    },
});
export default permissionSlice.reducer;
export const { resetPermissionState } = permissionSlice.actions;

// Thunks
export const getModulePermission = createAsyncThunk("permissionList/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getModulesPermissions + body.moduleId);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const roleModulePermissions = createAsyncThunk("roleModulePermissions/getPermissions", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        // console.log(body);
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.roleModulePermission + `?roleId=${body?.roleId}&subModuleId=${body?.subModuleId}`);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const addRoleModulePermissions = createAsyncThunk("addRoleModulePermissions/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        // console.log(body);
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addRoleModulePermission, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
