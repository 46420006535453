import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { Toast } from 'primereact/toast';
import { toast } from "react-toastify";
import { Checkbox } from "primereact/checkbox";
//import { UPDATE_USER_STATUS_RESET } from '../../../../../redux/constants/passenger_constatns';
//import { getDriversList } from '../../../../../redux/actions/drivers_actions';
import CustomInputField from "../../../components/custom_input_filed";
import { getUsersList, resetUpdateUsers, updateUserStatus } from "../../../../../redux/slices/users_slice";

export default function EditUserDialog({ onHide, editUser, permissions }) {
    //redux
    const editUserReducer = useSelector((state) => state.usersList);
    const { loading, error, success, updateSuccess, updateError } = editUserReducer;
    //hooks
    const dispatch = useDispatch();
    // const toast = useRef(null);
    const loadEditData = () => {
        formik.setFieldValue("status", editUser.active["status"]);
    };
    useEffect(() => {
        loadEditData();
    }, []);
    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                formik.resetForm();
                onHide();
                dispatch(getUsersList());
                //dispatch(getDriversList());
            }
        }

        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Status successfully updated");
                onHide();
                dispatch(getUsersList());
                window. location. reload();
            } else {
                toast.error(updateError);
            }
        }
        return () => {
            dispatch(resetUpdateUsers());
        };
        // return () => {
        //     dispatch({ type: UPDATE_USER_STATUS_RESET });
        // }
    }, [success, error, updateSuccess, updateError]);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: false,
            comment: "",
        },
        onSubmit: async (data) => {
            // //console.log(data);
            // if (data.status === false && data.comment.length === 0) {
            //     toast.current.show({ severity: 'warn', summary: 'Error Message', detail: 'Comment is required', life: 3000 });
            //     return;
            // }
            data["userId"] = editUser._id;
            if (editUser != null) {
                dispatch(updateUserStatus(data));
            }
        },
    });
    const validationSchema = Yup.object().shape({
        comment: Yup.string().required("Comment is required"),
    });

    return (
        <>
            {/* <Toast ref={toast} /> */}
            <form onSubmit={formik.handleSubmit}>
                {
                    <div className="grid">
                        <div className="col-12">
                            <h5>
                                {editUser?.firstName?.charAt(0).toUpperCase() + editUser?.firstName?.slice(1)} {editUser?.lastName?.charAt(0).toUpperCase() + editUser?.lastName?.slice(1)}
                            </h5>
                            <div className="p-fluid grid">
                                {/* <div className='grid'> */}
                                <div className="col-12">
                                    <div className="field-checkbox">
                                        <Checkbox inputId="status" name="status" checked={formik.values.status} onChange={formik.handleChange} />
                                        <label htmlFor="status">Active status</label>
                                    </div>
                                </div>
                                {!formik.values.status && (
                                    <div className="col-12">
                                        <div className="">
                                            <CustomInputField iden={"comment"} formik={formik} type="textarea" />
                                        </div>
                                    </div>
                                )}
                                {/* </div> */}

                                <div className="col-12">
                                    <div className="button_class-modal"> {permissions?.edit && <Button loading={loading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </form>
        </>
    );
}
