import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const RolesSlice = createSlice({
    name: "roles",
    initialState: {},
    reducers: {
        resetAddEditRoles(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addRoles.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(addRoles.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addRoles.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(updateRoles.pending, (state, action) => {
                return { ...state, addLoading: true, editSuccess: undefined };
            })
            .addCase(updateRoles.fulfilled, (state, action) => {
                return { ...state, addLoading: false, editSuccess: true };
            })
            .addCase(updateRoles.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });

        builder
            .addCase(roleModules.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(roleModules.fulfilled, (state, action) => {
                return { ...state, modulesList: action.payload };
            })
            .addCase(roleModules.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
    },
});

export default RolesSlice.reducer;
export const { resetAddEditRoles } = RolesSlice.actions;

// Thunks
export const addRoles = createAsyncThunk("addRoles/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addRoles, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const updateRoles = createAsyncThunk("updateRoles/update", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.updateRoles, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const roleModules = createAsyncThunk("roleModules/get", async (roleId, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.roleModulesList + `?roleId=${roleId}`);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
