import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_filed";
import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function AddEditTutorialCategory({ onHide, editData, permissions }) {
    const tutorialCategoryReducer = useSelector((state) => state.tutorialCategoryList);
    const { addLoading, addError, updateError, updateLoading, addSuccess, updateSuccess } = tutorialCategoryReducer;
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Category is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
        },

        onSubmit: async (data) => {
            if (editData === null) {
                dispatch(addTutorialsCategory(data));
            } else {
                data["categoryId"] = editData._id;
                dispatch(updateTutorialsCategory(data));
                ////console.log(data)
            }
        },
    });

    //properties
    useEffect(() => {
        if (editData != null) {
            loadInitialValues();
        }
    }, []);
    const loadInitialValues = () => {
        formik.setFieldValue("name", editData.name);
    };

    // useEffect(() => {
    //     if (editData !== null) {
    //         formik.setFieldValue("name", editData.name);
    //     }
    // }, []);
    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Category successfully added");

                onHide();
                dispatch(getTutorialsCategoryList());
            } else {
                toast.error(addError);
            }
        }
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Category successfully updated");

                onHide();
                dispatch(getTutorialsCategoryList());
            } else {
                toast.error(updateError);
            }
        }

        return () => {
            dispatch(resetAddEditTutorialsCategory());
        };
    }, [addSuccess, updateSuccess, addError, updateError]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <CustomInputField iden="name" label="Category" formik={formik} type="text" />
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                {permissions?.add && editData == null && <Button loading={editData === null ? addLoading : updateLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}{" "}
                                {permissions?.edit && editData && <Button loading={editData === null ? addLoading : updateLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
