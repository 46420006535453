import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import appUrl from "../../../constants/appUrl";
import { formateDateTime } from "../../../services/date_service";
import { Paginator } from "primereact/paginator";

export default function AllRidesTableComponent({ rides, loading, userType, count, setPageNumber, flag, permissions, rows, setRows }) {
    const history = useHistory();
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-ellipsis-h"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            if (userType === "1") {
                                history.push(`/api/rides/${flag}/driver/` + rowData._id);
                            } else {
                                history.push(`/api/rides/${flag}/passenger/` + rowData._id);
                            }
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setPageNumber(event.first / event.rows);
        setPage(event.first / event.rows);
    };

    return (
        <div className="card">
            <DataTable loading={loading} responsiveLayout="scroll" value={rides} key="_id" emptyMessage="No record found.">
                <Column body={(data, props) => <div>{page * 50 + props.rowIndex + 1}</div>} header="Sr" />

                <Column
                    header="Image"
                    body={(data) => {
                        return (
                            <div>
                                <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data?.user?.profileImage}`} />
                            </div>
                        );
                    }}
                />
                <Column filter field="user.firstName" header="First Name" />
                <Column filter field="user.lastName" header="Last Name" />
                <Column filter field="user.mobile" header="Mobile" />
                <Column filter field="startPoint.placeName" header="Pickup location" />
                <Column filter field="endPoint.placeName" header="Dropoff location" />
                <Column filter body={(data) => <div>{formateDateTime(data.date)}</div>} field="date" header="Date" />
                {userType === "2" && <Column filter field="bookedSeats" header="Booked seats" />}
                <Column filter field="gender" header="Gender" />
                <Column
                    filter
                    field="status"
                    body={(data) => {
                        let status = data.status === "active" ? "Upcoming" : "Active";
                        return <div>{status}</div>;
                    }}
                    header="Status"
                />
                <Column header="Action" body={actionTemplate} />
            </DataTable>
            <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
        </div>
    );
}
