import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomInputField from "../../../components/custom_input_filed";
import { addModules, editModule, getModulesList, resetModulesList } from "../../../../../redux/slices/modules_slice";
import { useEffect } from "react";
import { toast } from "react-toastify";
export default function AddEditModule({ editData, onHide, permissions }) {
    const dispatch = useDispatch();
    const modulesListReducer = useSelector((state) => state.modulesList);
    const { addSuccess, editSuccess } = modulesListReducer;

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name field is required."),
        icon: Yup.string().required("Icon field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            icon: "",
        },

        onSubmit: async (data) => {
            if (editData == null) {
                dispatch(addModules(data));
            } else {
                data["moduleId"] = editData._id;
                dispatch(editModule(data));
            }
        },
    });

    useEffect(() => {
        if (editData !== null) {
            loadValues();
        }
    }, []);

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Module successfully added");
                onHide();
                dispatch(getModulesList());
            } else {
                // toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Module successfully updated");
                onHide();
                dispatch(getModulesList());
            } else {
                // toast.error(editError);
            }
        }
        return () => {
            dispatch(resetModulesList());
        };
    }, [addSuccess, editSuccess]);

    const loadValues = () => {
        formik.setFieldValue("name", editData.name);
        formik.setFieldValue("icon", editData.icon);
        formik.setFieldValue("route", editData.route);
        formik.setFieldValue("orderPosition", editData.orderPosition);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className=" p-fluid grid">
                        <div className="col-12 md:col-6">
                            <CustomInputField iden="name" label="Name" formik={formik} />
                        </div>
                        <div className="col-12 md:col-6">
                            <CustomInputField iden="icon" label="Icon" formik={formik} />
                        </div>

                        <div className="col-12">
                            <div className="button_class-modal">{permissions?.add && editData == null && <Button type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />} </div>
                            <div className="button_class-modal">{permissions?.edit && editData && <Button type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />} </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
