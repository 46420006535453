import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCorporateCode, editCorporateCode, getCorporateCodesList, resetAddEditCorporateCode, getGoogleAddress } from "../../../../../redux/slices/corporate_code_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

import { ReactSearchAutocomplete } from "react-search-autocomplete";
export default function AddCorporateCodeDialog({ onHide, editCode, permissions }) {
    //redux
    const corporateCodeReducer = useSelector((state) => state.corporateCode);
    const modifiedLocations = corporateCodeReducer?.address?.map((location) => ({ title: location }));
    const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = corporateCodeReducer;
    const [selectedAddress, setSelectedAddress] = useState("");
    const dispatch = useDispatch();
    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        code: Yup.string().required("This field is required.").min(11, "Please enter valid code xxxx-xxxxxx").max(11, "Please enter valid code xxxx-xxxxxx"),
        address: Yup.string(),
        fee: Yup.number().typeError("Please enter a valid number").min(-100, "Number must be greater than or equal to -100").max(100, "Number must be less than or equal to 100").required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            code: "",
            address: "",
            fee: "",
        },

        onSubmit: async (data) => {
            const obj = {
                address: selectedAddress,
                code: data.code,
                fee: data.fee,
                name: data.name,
            };
            if (editCode == null) {
                dispatch(addCorporateCode(obj));
            } else {
                obj["corporateId"] = editCode._id;
                dispatch(editCorporateCode(obj));
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Corporate Code successfully added");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Corporate Code successfully updated");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(editError);
            }
        }
        return () => {
            dispatch(resetAddEditCorporateCode());
        };
    }, [addSuccess, addError, editSuccess, editError]);

    useEffect(() => {
        if (editCode !== null) {
            loadInitialValues();
        }
    }, []);

    const loadInitialValues = () => {
        formik.setFieldValue("name", editCode.name);
        formik.setFieldValue("address", editCode.address);
        formik.setFieldValue("code", editCode.code);
        formik.setFieldValue("fee", editCode.fee);
        formik.setFieldValue("active", editCode["active"]);
    };
    const [searchText, setSearchText] = useState("");

    const handleInputChange = (event) => {
        const text = event;

        setSelectedAddress(event);
        // Update the search text
        setSearchText(text);

        dispatch(getGoogleAddress({ text }));
    };
    const handleOnSelect = (e) => {
        console.log(e, "yes");
        setSelectedAddress(e.title);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="name" formik={formik} type="text" disabled={editCode !== null} />
                </div>
                <div className="p-fluid col-12 md:col-6">{editCode !== null ? <CustomInputField iden="code" formik={formik} placeHolder="xxxx-xxxxxx" type="text" disabled={true} /> : <CustomInputField iden="code" formik={formik} mask="****-******" placeHolder="xxxx-xxxxxx" type="text" />} </div>
                <div className="p-fluid col-12 md:col-6 " style={{ padding: "0 30px" }}>
                    {/* <CustomInputField iden="address" formik={formik} handleInputChange={handleInputChange} type="text" disabled={editCode !== null} searchText={searchText} onChange={handleInputChange} /> */}
                    <p>Addresss</p>
                    <ReactSearchAutocomplete
                        items={modifiedLocations}
                        fuseOptions={{ keys: ["title"] }} // Search on both fields
                        resultStringKeyName="title" // String to display in the results
                        onSearch={handleInputChange}
                        onChange={handleInputChange}
                        // onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        // onFocus={handleOnFocus}
                        // onClear={handleOnClear}
                        showIcon={false}
                        styling={{
                            height: "34px",

                            borderRadius: "4px",
                            backgroundColor: "white",
                            boxShadow: "none",
                            hoverBackgroundColor: "lightgreen",
                            color: "darkgreen",
                            fontSize: "12px",
                            border: "1px solid #ced4da",
                            fontFamily: "Courier",
                            iconColor: "green",
                            lineColor: "lightgreen",
                            placeholderColor: "darkgreen",
                            clearIconMargin: "3px 8px 0 0",
                            zIndex: 2,
                        }}
                    />
                </div>
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="fee" formik={formik} type="number" />
                </div>
                {editCode != null && (
                    <div className="p-fluid col-12 md:col-6">
                        <div className="field">
                            <CustomInputField iden={"active"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                        </div>
                    </div>
                )}
                <div className="p-fluid col-12">
                    <div className="button_class-modal">{permissions?.edit && editCode !== null && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                    <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                </div>
            </div>
        </form>
    );
}
